import React from 'react';
import Typist from 'react-typist';
import Gallery from 'react-grid-gallery';
import firebase from '../Firestore'
import styles from './Gallery.module.css'
import Lottie from 'lottie-react-web'
import loadingWork from '../Animation/loaderAnim2.json'
import VizSensor from 'react-visibility-sensor';
import { connect } from 'react-redux'
import { addGalleryData, setCurrentGalleryNumber } from '../Store/actions/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

class ImageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    render() {
        return (
            <VizSensor
                partialVisibility={false}
                offset={-100}
                onChange={(isVisible) => {
                    if (isVisible) { this.setState({ show: true }) }
                }}>
                {this.state.show ? <img {...this.props.imageProps} /> : <img onMouseOver={() => this.setState({ show: true })} src={require('../Images/paddyavtar.png')} style={Object.assign({}, this.props.imageProps.style, { textAlign: 'center' })} alt="Snow" />}
            </VizSensor>
        )
    }
}

class GalleryComponet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: this.props.images,
            photoSource: '',
            photoTitle: '',
            photothumbSource: '',
            bnwPhotos: false,
            paintingPhotos: false,
        };
    }

    componentDidMount() {
        if (!this.props.user.isVisitedGallery) {
            firebase.analytics().logEvent('galleryPage')
            this.getGalleryData()
        }
    }

    getGalleryData = () => {
        const db = firebase.firestore();
        db.collection("photos")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                console.log("firebase", data); // array of cities objects
                let newArray = data
                for (let i = 0; i < newArray.length; i++) {
                    newArray[i].thumbnailHeight = 300 + Math.floor(Math.random() * 100);
                    newArray[i].thumbnailWidth = 300
                }

                console.log("firebase2", newArray);
                this.props.addGalleryData(newArray, 1)
            });

        db.collection("bnwphotos")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                console.log("firebase2", data); // array of cities objects
                let newArray = data
                for (let i = 0; i < newArray.length; i++) {
                    newArray[i].thumbnailHeight = 300 + Math.floor(Math.random() * 100);
                    newArray[i].thumbnailWidth = 300
                }
                this.props.addGalleryData(newArray, 2)
            });
        db.collection("painitngphotos")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                console.log("firebase2", data); // array of cities objects
                let newArray = data
                for (let i = 0; i < newArray.length; i++) {
                    newArray[i].thumbnailHeight = 300 + Math.floor(Math.random() * 100);
                    newArray[i].thumbnailWidth = 300
                }
                this.props.addGalleryData(newArray, 3)
            });
    }

    choiceClick = (number) => {
        this.props.setCurrentGalleryNumber(number)
    }

    render() {
        const { galleryColor, galleryBlack, galleryPaint, isVisitedGallery, currntGalleryNumber } = this.props.user
        const isMobile = window.innerWidth;
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    marginTop: 20,
                    color: color,
                    backgroundColor: color,
                    height: 0.02
                }}
            />
        );

        let navigationComp = null;
        navigationComp = (
            <div style={{ marginTop: 40, width: isMobile <= 500 ? '75%' : '55%', }}>
                <div onClick={() => this.props.history.goBack()} style={{ cursor: 'pointer', }}>
                    <div style={{ display: 'inline-block' }}>   <FontAwesomeIcon icon={faArrowLeft} style={{ color: '#FFD800', }} />
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <h1 style={{ fontSize: 22, color: '#FFD800', fontFamily: "Open Sans", marginLeft: 10, fontWeight: 'bold' }}>/Gallery </h1>
                    </div>
                </div>
            </div>
        )
        let loadingUI = null
        loadingUI = (
            <div style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '100vh', backgroundColor: '#000000', paddingTop: 180 }}>
                <Lottie
                    style={{ height: 330, width: 280, marginTop: 50 }}
                    options={{
                        animationData: loadingWork
                    }}
                />
            </div>
        )

        let gallery = null

        gallery = (
            <div style={{
                display: "block",
                minHeight: "1px",
                overflow: "auto",
                backgroundColor: '#000000',
                width: isMobile <= 500 ? '75%' : '55%', padding: 20
            }}>

                <Typist><h1 style={{ color: 'white', fontFamily: "Bungee", fontSize: 20, margin: 0 }}>Every picture has a story behind it.</h1></Typist>

                <div className={styles.flexContainerChild}>
                    <div style={{ display: isMobile <= 500 ? 'null' : 'inline-block' }}> <a style={{ cursor: 'pointer' }} onClick={() => this.choiceClick(1)}> <h1 style={{ fontSize: currntGalleryNumber === 1 ? 17 : 15, color: currntGalleryNumber === 1 ? '#FFD800' : 'grey' }}>Colored / </h1> </a> </div>
                    <div style={{ display: isMobile <= 500 ? 'null' : 'inline-block' }}> <a style={{ cursor: 'pointer' }} onClick={() => this.choiceClick(2)}> <h1 style={{ fontSize: currntGalleryNumber === 2 ? 17 : 15, marginLeft: 5, color: currntGalleryNumber === 2 ? '#FFD800' : 'grey' }}>Black&Whites /</h1> </a> </div>
                    <div style={{ display: isMobile <= 500 ? 'null' : 'inline-block' }}> <a style={{ cursor: 'pointer' }} onClick={() => this.choiceClick(3)}> <h1 style={{ fontSize: currntGalleryNumber === 3 ? 17 : 15, marginLeft: 5, color: currntGalleryNumber === 3 ? '#FFD800' : 'grey' }}>Paintings</h1> </a> </div>
                    <ColoredLine color="grey" />
                </div>
                <div style={{
                    backgroundColor: '#000000',
                    marginTop: 25
                }}>
                    <Gallery
                        images={currntGalleryNumber === 1 ? galleryColor : currntGalleryNumber === 2 ? galleryBlack : galleryPaint}
                        thumbnailImageComponent={ImageComponent}
                        enableImageSelection={false} />

                </div>
            </div>
        )

        return (
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                flex: 1,
                width: '100%',
                backgroundColor: '#000000',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }} >
                {navigationComp}
                {isVisitedGallery ? gallery : loadingUI}
                <div className={styles.footer} >
                    <p className={styles.autherName}>© Pradnyanand Milind Pohare</p>
                </div>

            </div>
        );
    }
}


export default connect((app) => ({ user: app.user }), (dispatchEvent) => ({
    addGalleryData: (data, number) => dispatchEvent(addGalleryData(data, number)),
    setCurrentGalleryNumber: (number) => dispatchEvent(setCurrentGalleryNumber(number)),
}))(GalleryComponet)