import React, { Component } from 'react'
import styles from '../Home/Home.module.css'

import firebase from '../Firestore'

function triggerLink(productUrl, productName) {
    firebase.analytics().logEvent('ProductLinkClicked', { productName: productName, })
    window.open(productUrl, "_blank")

}

const ProductCardItem = (props) => {
    return <div onClick={() => triggerLink(props.productUrl, props.productName)} className={styles.itemproduct}>
        <div className={styles.productleftcontainer}>
            <img src={props.productImage} className={styles.productimg} />


        </div>
        <div className={styles.productrightcontainer}>
            <h3 className={styles.productname}>
                {props.productName}
            </h3>
            <h3 className={styles.productdescription}>
                {props.productDescription}
            </h3>
        </div>
    </div>
}


export default ProductCardItem;