import React, { Component } from 'react'
import styles from '../Home/Home.module.css'
import firebase from '../Firestore'

function triggerLink(productUrl, productName) {
    firebase.analytics().logEvent('ProductLinkClicked', { productName: productName, })
    window.open(productUrl, "_blank")

}

const HorizontalCard = (props) => {
    return <div onClick={() => triggerLink(props.productUrl, props.productName)} className={styles.cameraproduct}>
        <div className={styles.productleftcontainerheight}>
            <img src={props.productImage} className={styles.productimgHorizonal} />
        </div>
        <div className={styles.productrightcontainerhright}>
            <h3 className={styles.productnamealt}>
                {props.productName}
            </h3>
            <h3 className={styles.productdescriptionalt}>
                {props.productDescription}
            </h3>
        </div>
    </div>
}


export default HorizontalCard;