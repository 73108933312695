import { ADD_HOME_DATA, ADD_WORK_DATA, ADD_BLOG_DATA, ADD_BLOG_DATA_PERSONAL, IS_ON_PROFESSIONAL, ADD_GALLERY_COLOR, ADD_GALLERY_BLACK, ADD_GALLERY_PAINT, CURRENT_GALLERY_NUM, ADD_YT_DATA, ADD_PRODUCT_LINKS, ADD_CAMERA_LINKS, ADD_INSTRUMENTS_LINKS, ADD_DECOR_LINKS, ADD_FEATURED_BLOGS, ADD_MY_BAGS, ADD_MY_BOOKS, ADD_BNW_SHOW, ADD_PAINTINGS_SHOW } from "../actions/actions";

const initialState = {
    isVisitedHome: false,
    homeData: [],
    isVisitedWork: false,
    workData: [],
    isVisitedBlog: false,
    blogData: [],
    blogDataId: [],
    blogDataPersonal: [],
    blogDataPersonalId: [],
    isOnProfessional: true,
    galleryColor: [],
    galleryBlack: [],
    galleryPaint: [],
    isVisitedGallery: false,
    currntGalleryNumber: 1,
    youtubeData: [],
    productLinks: [],
    cameraLinks: [],
    instrumentsLinks: [],
    decorLinks: [],
    featuredBlogs: [],
    myBags: [],
    myBooks: [],
    myPainitingsShows: [],
    myBnwShows: []


}

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_BNW_SHOW:
            console.log('isVisitedHome33 reducer', action.payload)
            return {
                ...state,
                myBnwShows: action.payload,
                isVisitedHome: true
            }
        case ADD_PAINTINGS_SHOW:
            console.log('isVisitedHome33 reducer', action.payload)
            return {
                ...state,
                myPainitingsShows: action.payload,
                isVisitedHome: true
            }
        case ADD_MY_BAGS:
            console.log('isVisitedHome33 reducer', action.payload)
            return {
                ...state,
                myBags: action.payload,
                isVisitedHome: true
            }
        case ADD_MY_BOOKS:
            console.log('isVisitedHome33 reducer', action.payload)
            return {
                ...state,
                myBooks: action.payload,
                isVisitedHome: true
            }
        case ADD_HOME_DATA:
            console.log('isVisitedHome33 reducer', action.payload)
            return {
                ...state,
                homeData: action.payload,
                isVisitedHome: true
            }
        case ADD_FEATURED_BLOGS:
            console.log('isVisitedHome33 reducer', action.payload)
            return {
                ...state,
                featuredBlogs: action.payload,
                isVisitedHome: true
            }
        case ADD_DECOR_LINKS:
            console.log('isVisitedHome33 reducer', action.payload)
            return {
                ...state,
                decorLinks: action.payload,
                isVisitedHome: true
            }
        case ADD_INSTRUMENTS_LINKS:
            console.log('isVisitedHome33 reducer', action.payload)
            return {
                ...state,
                instrumentsLinks: action.payload,
                isVisitedHome: true
            }
        case ADD_CAMERA_LINKS:
            console.log('isVisitedHome33 reducer', action.payload)
            return {
                ...state,
                cameraLinks: action.payload,
                isVisitedHome: true
            }
        case ADD_YT_DATA:
            return {
                ...state,
                youtubeData: action.payload,
            }
        case ADD_PRODUCT_LINKS:
            return {
                ...state,
                productLinks: action.payload,
            }
        case ADD_WORK_DATA:
            console.log('ADD_WORK_DATA reducer', action.payload)
            return {
                ...state,
                workData: action.payload,
                isVisitedWork: true
            }
        case ADD_BLOG_DATA:
            console.log('ADD_BLOG_DATA reducer', action.payload, action.payloadId)
            return {
                ...state,
                blogData: action.payload,
                blogDataId: action.payloadId,
                isVisitedBlog: true
            }
        case ADD_BLOG_DATA_PERSONAL:
            console.log('ADD_BLOG_DATA_PERSONAL reducer', action.payload, action.payloadId)
            return {
                ...state,
                blogDataPersonal: action.payload,
                blogDataPersonalId: action.payloadId,
                isVisitedBlog: true
            }
        case IS_ON_PROFESSIONAL:
            console.log('IS_ON_PROFESSIONAL reducer', action.payload)
            return {
                ...state,
                isOnProfessional: action.payload,
            }
        case ADD_GALLERY_COLOR:
            console.log('ADD_GALLERY_COLOR reducer', action.payload)
            return {
                ...state,
                galleryColor: action.payload,
                isVisitedGallery: true,
            }
        case ADD_GALLERY_BLACK:
            console.log('ADD_GALLERY_BLACK reducer', action.payload)
            return {
                ...state,
                galleryBlack: action.payload,
                isVisitedGallery: true,
            }
        case ADD_GALLERY_PAINT:
            console.log('ADD_GALLERY_PAINT reducer', action.payload)
            return {
                ...state,
                galleryPaint: action.payload,
                isVisitedGallery: true,
            }
        case CURRENT_GALLERY_NUM:
            console.log('CURRENT_GALLERY_NUM reducer', action.payload)
            return {
                ...state,
                currntGalleryNumber: action.payload,
            }
        default: {
            return { ...state };
        }
    }
}
