import React, { Component } from 'react'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './layout.module.css'

const CardItem = (props) => {
    return <div onClick={() => props.goBack()} className={styles.container}>
        <div className={styles.subContainer}>   <FontAwesomeIcon icon={faArrowLeft} className={styles.backIcon} />
        </div>
        <div className={styles.subContainer}>
            <h1 className={styles.backTitle}>{props.headerName}</h1>
        </div>
    </div>
}

export default CardItem;