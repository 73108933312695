import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/analytics/';
import 'firebase/auth'


var config = {
    apiKey: "AIzaSyBUfLa74RWEbhiiOURjq46ItDClXqO2z1Q",
    authDomain: "portfolio-4e28f.firebaseapp.com",
    databaseURL: "https://portfolio-4e28f.firebaseio.com",
    projectId: "portfolio-4e28f",
    storageBucket: "portfolio-4e28f.appspot.com",
    messagingSenderId: "813181692235",
    appId: "1:813181692235:web:29ee5e1d966d5933bce0ce",
    measurementId: "G-Y771Q651K9"


  
  };
  firebase.initializeApp(config);
  firebase.analytics();
  firebase.auth();

  export default firebase;
