import React, { Component } from 'react'
import "react-alice-carousel/lib/alice-carousel.css";
import "react-image-gallery/styles/css/image-gallery.css"
import firebase from '../Firestore'
import styles from './Blogs.module.css'
import 'react-tabs/style/react-tabs.css';
import { NavLink } from 'react-router-dom'
import Lottie from 'lottie-react-web'
import loadingWork from '../Animation/loaderAnim2.json'
import { connect } from 'react-redux'
import { addBlogData, isOnProfessionalAction } from '../Store/actions/actions'
import BackNavItem from '../layouts/backNavItem'

class Blogs extends Component {

    componentDidMount() {
        if (!this.props.user.isVisitedBlog) {
            firebase.analytics().logEvent('BlogPage')
            this.getBlogData()
        }
    }

    getBlogData = () => {
        const db = firebase.firestore();
        db.collection("pradnyanandBlogs")
            .orderBy('blogNumber')
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                const dataId = querySnapshot.docs.map(doc => doc.id);
                this.props.addBlogData(data.reverse(), dataId.reverse(), false)
            });

        db.collection("pradnyanandPersonalBlogs")
            .orderBy('blogNumber')
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                const dataId = querySnapshot.docs.map(doc => doc.id);
                this.props.addBlogData(data.reverse(), dataId.reverse(), true)
            });
    }

    blogsClick = () => {
        console.log("blogsClick blog");
        this.props.isOnProfessionalAction(!this.props.user.isOnProfessional)
    }

    render() {
        let mobileUI = null;
        let navigationComp = null;
        const isMobile = window.innerWidth;
        const { blogData, blogDataId, blogDataPersonal, blogDataPersonalId, isOnProfessional } = this.props.user

        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    height: 0.01,
                    margin: 0,
                    marginBottom: 20,
                    marginTop: 5
                }}
            />
        );

        navigationComp = (
            <div style={{ marginTop: 40, width: isMobile <= 500 ? '85%' : '55%', }}>
                <BackNavItem
                    headerName={'/Blog'}
                    goBack={this.props.history.goBack}
                />
            </div>
        )

        if (!this.props.user.isVisitedBlog) {
            mobileUI = (
                <div className={styles.subContainer2}>
                    <Lottie
                        style={{ height: 330, width: 280, marginTop: 50 }}
                        options={{
                            animationData: loadingWork
                        }}
                    />
                </div>
            )
        } else {
            if (isMobile <= 500) {

                mobileUI = (
                    <div className={styles.subContainer}>
                        {navigationComp}
                        <div style={{ display: 'inline-block' }}>
                            <a style={{ cursor: 'pointer' }} onClick={() => this.blogsClick()}>
                                <h1 style={{ fontSize: isOnProfessional ? 15 : 12, color: isOnProfessional ? '#000000' : 'grey', textDecorationColor: '#000000', fontFamily: "Helvetica Neue", }}>Pradnyanand's Blog / </h1>
                            </a>
                        </div>
                        <div style={{ display: 'inline-block' }}>
                            <a style={{ cursor: 'pointer' }} onClick={() => this.blogsClick()}>
                                <h1 style={{ fontSize: isOnProfessional ? 12 : 15, marginLeft: 5, color: isOnProfessional ? 'grey' : '#000000', textDecorationColor: '#000000', fontFamily: "Helvetica Neue", }}>Personal Blogs</h1>
                            </a>
                        </div>
                        <h1 style={{ fontSize: 15, color: '#000000', fontFamily: "Helvetica Neue", margin: 0, }}>{isOnProfessional ? 'Pradnyanands blogs are about his experience while learning new things, technology and ideas.' : 'This section of blogs are about his learnings, his thoughts and his hobbies.'}</h1>
                        <ColoredLine color={'#000000'} />
                        {isOnProfessional ?
                            blogData && blogData.map(function (item, index) {
                                item.docId = blogDataId[index];
                                item.blogsname = '1'
                                return (
                                    <div style={{ position: 'relative', marginTop: 5 }}>
                                        <NavLink
                                            style={{ textDecorationColor: '#000000' }}
                                            to={{
                                                pathname: "/BlogsMore/" + "1" + "/" + blogDataId[index],
                                                data: item,
                                            }}>
                                            <h1 style={{ fontSize: 14, color: '#000000', margin: 0, padding: 0, fontFamily: 'Roboto Mono' }}>{item.header}</h1>
                                        </NavLink>
                                        <p style={{ fontSize: 14, fontStyle: 'italic', margin: 0, color: '#000000' }}> {item.date}</p>
                                    </div>

                                )
                            }) : blogDataPersonal.length > 0 ? blogDataPersonal.map(function (item, index) {
                                item.docId = blogDataPersonalId[index];
                                item.blogsname = '2'
                                return (
                                    <div style={{ position: 'relative', marginTop: 5 }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <NavLink style={{ textDecorationColor: '#000000' }} to={{
                                                pathname: "/BlogsMore/" + "2" + "/" + blogDataPersonalId[index],
                                                data: item,
                                            }}>
                                                <h1 style={{ fontSize: 14, color: '#000000', margin: 0, padding: 0, fontFamily: 'Roboto Mono' }}>{item.header}</h1>
                                            </NavLink>
                                        </div>
                                        <div style={{ display: 'inline-block' }}><p style={{ fontSize: 14, fontStyle: 'italic', margin: 0, color: '#000000' }}> {item.date}</p></div>
                                    </div>
                                )
                            }) : <div className={styles.containerdiv}>
                                <div className={styles.flexContainer} >
                                    <div className={styles.flexContainerChild}>
                                        <h1 style={{ fontSize: 20, }}>Writing Soon.</h1>
                                    </div>
                                </div>
                            </div>}
                        <p className={styles.autherName}>© Pradnyanand Milind Pohare</p>
                    </div>
                )
            }
            else {

                mobileUI = (
                    <div className={styles.subContainer}>
                        {navigationComp}
                        <div style={{ display: 'inline-block' }}>
                            <a style={{ cursor: 'pointer' }} onClick={() => this.blogsClick()}>
                                <h1 style={{ fontSize: isOnProfessional ? 20 : 15, color: isOnProfessional ? '#000000' : 'grey', textDecorationColor: '#000000', fontFamily: "Helvetica Neue", }}>
                                    Pradnyanand's Blog /
                                </h1>
                            </a>
                        </div>
                        <div style={{ display: 'inline-block' }}>
                            <a style={{ cursor: 'pointer' }} onClick={() => this.blogsClick()}>
                                <h1 style={{ fontSize: isOnProfessional ? 15 : 20, marginLeft: 5, color: isOnProfessional ? 'grey' : '#000000', textDecorationColor: '#000000', fontFamily: "Helvetica Neue", }}>
                                    Personal Blogs
                                </h1>
                            </a>
                        </div>
                        <h1 style={{ fontSize: 14, color: '#000000', fontFamily: "Helvetica Neue", margin: 0, }}>{isOnProfessional ? 'Pradnyanands blogs are about his experience while learning new things, technology and ideas.' : 'This section of blogs are about his learnings, his thoughts and his hobbies.'}</h1>
                        <ColoredLine color={'#000000'} />

                        {isOnProfessional ?
                            blogData && blogData.map(function (item, index) {
                                item.docId = blogDataId[index];
                                item.blogsname = '1'
                                return (
                                    <div style={{ position: 'relative', marginTop: 5 }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <NavLink
                                                style={{ textDecorationColor: '#000000' }}
                                                to={{
                                                    pathname: "/BlogsMore/" + "1" + "/" + blogDataId[index],
                                                    data: item,
                                                }}
                                            >
                                                <h1 style={{ fontSize: 14, color: '#000000', margin: 0, padding: 0, fontFamily: 'Roboto Mono' }}>{item.header}</h1>
                                            </NavLink>
                                        </div>
                                        <div style={{ display: 'inline-block' }}><p style={{ fontSize: 14, fontStyle: 'italic', margin: 0, marginLeft: 10, color: '#000000' }}> {item.date}</p></div>
                                    </div>

                                )
                            }) : blogDataPersonal.length > 0 ? blogDataPersonal.map(function (item, index) {
                                item.docId = blogDataPersonalId[index];
                                item.blogsname = '2'
                                return (
                                    <div style={{ position: 'relative', marginTop: 5 }}>
                                        <div style={{ display: 'inline-block' }}>
                                            <NavLink style={{ textDecorationColor: '#000000' }} to={{
                                                pathname: "/BlogsMore/" + "2" + "/" + blogDataPersonalId[index],
                                                data: item,
                                            }}
                                            >
                                                <h1 style={{ fontSize: 14, color: '#000000', margin: 0, padding: 0, fontFamily: 'Roboto Mono' }}>{item.header}</h1>
                                            </NavLink>
                                        </div>
                                        <div style={{ display: 'inline-block' }}><p style={{ fontSize: 14, fontStyle: 'italic', margin: 0, marginLeft: 10, color: '#000000' }}> {item.date}</p></div>
                                    </div>
                                )
                            }) : <div className={styles.containerdiv}>
                                <div className={styles.flexContainer} >
                                    <div className={styles.flexContainerChild}>
                                        <h1 style={{ fontSize: 20, }}>Writing Soon.</h1>
                                    </div>
                                </div>
                            </div>}
                        <p className={styles.autherName}>© Pradnyanand Milind Pohare</p>
                    </div>
                )
            }
        }
        return (

            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                flex: 1,
                width: '100%',
                backgroundColor: '#f6ede0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }} >

                {mobileUI}
            </div>
        )
    }
}


export default connect((app) => ({ user: app.user }), (dispatchEvent) => ({
    addBlogData: (data, dataId, bool) => dispatchEvent(addBlogData(data, dataId, bool)),
    isOnProfessionalAction: (bool) => dispatchEvent(isOnProfessionalAction(bool)),
}))(Blogs)
