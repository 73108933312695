import React, { Component } from 'react'
import styles from './Home.module.css'
import firebase from '../Firestore'
import { connect } from 'react-redux'
import { addHomeData, addYTData, addProductLinks, addCameraLinks, addInstrumentLinks, addDecorLinks, addFeaturedBlogs, addMyBag, addMyBooks, addBnwPhotosShow, addPaintingsShow } from '../Store/actions/actions'
import CardItem from '../layouts/cardItem'
import ProductCardItem from '../layouts/productCardItem'
import HorizontalCard from '../layouts/horizontalCardItem'
import ProductCardItemAlt from '../layouts/productCardItemAlt'
import ImageItem from '../layouts/imageItem'
import loading from '../Animation/paddyLoading.json'
import scroll from '../Animation/scrolldown.json'
import scrollhorizontal from '../Animation/scroll.json'
import Lottie from 'lottie-react-web'

export class Home extends Component {

    componentDidMount() {
        console.log('isVisitedHome', this.props.user.isVisitedHome)
        if (!this.props.user.isVisitedHome) {
            this.getStatusData()
            this.getYoutubeData()
            this.getProductLiks()
            this.getCameraLinks()
            this.getInstrumentLinks()
            this.getDecorLinks()
            this.getFeaturedBlog()
            this.getMyBooks()
            this.getMyBag()
            this.getPaintingsShow()
            this.getBnwPhotosShow()
            firebase.analytics().logEvent('homePage')
        }
    }

    getBnwPhotosShow = () => {
        const db = firebase.firestore();
        db.collection("bnwPhotosShow")
            .get()
            .then(querySnapshot => {
                console.log('isVisitedHome fetch', querySnapshot.docs.map(doc => doc.data()))
                this.props.addBnwPhotosShow(querySnapshot.docs.map(doc => doc.data()))
            });

    }

    getPaintingsShow = () => {
        const db = firebase.firestore();
        db.collection("paintingsShow")
            .get()
            .then(querySnapshot => {
                console.log('paintingsShow fetch', querySnapshot.docs.map(doc => doc.data()))
                this.props.addPaintingsShow(querySnapshot.docs.map(doc => doc.data()))
            });

    }

    getMyBooks = () => {
        const db = firebase.firestore();
        db.collection("myBookCollection")
            .get()
            .then(querySnapshot => {
                console.log('isVisitedHome fetch', querySnapshot.docs.map(doc => doc.data()))
                this.props.addMyBooks(querySnapshot.docs.map(doc => doc.data()))
            });

    }

    getMyBag = () => {
        const db = firebase.firestore();
        db.collection("myBagProducts")
            .get()
            .then(querySnapshot => {
                console.log('isVisitedHome fetch', querySnapshot.docs.map(doc => doc.data()))
                this.props.addMyBag(querySnapshot.docs.map(doc => doc.data()))
            });

    }

    getFeaturedBlog = () => {
        const db = firebase.firestore();
        db.collection("featuredBlogs")
            .get()
            .then(querySnapshot => {
                console.log('isVisitedHome fetch', querySnapshot.docs.map(doc => doc.data()))
                this.props.addFeaturedBlogs(querySnapshot.docs.map(doc => doc.data()))
            });

    }

    getDecorLinks = () => {
        const db = firebase.firestore();
        db.collection("decorLinks")
            .get()
            .then(querySnapshot => {
                console.log('isVisitedHome fetch', querySnapshot.docs.map(doc => doc.data()))
                this.props.addDecorLinks(querySnapshot.docs.map(doc => doc.data()))
            });

    }

    getStatusData = () => {
        const db = firebase.firestore();
        db.collection("pradnyanandStatus")
            .get()
            .then(querySnapshot => {
                console.log('isVisitedHome fetch', querySnapshot.docs.map(doc => doc.data()))
                this.props.addHomeData(querySnapshot.docs.map(doc => doc.data()))
            });

    }

    getCameraLinks = () => {
        const db = firebase.firestore();
        db.collection("cameraGearLinks")
            .get()
            .then(querySnapshot => {
                console.log('cameraGearLinks fetch', querySnapshot.docs.map(doc => doc.data()))
                this.props.addCameraLinks(querySnapshot.docs.map(doc => doc.data()))
            });

    }

    getInstrumentLinks = () => {
        const db = firebase.firestore();
        db.collection("myInstruments")
            .get()
            .then(querySnapshot => {
                console.log('cameraGearLinks fetch', querySnapshot.docs.map(doc => doc.data()))
                this.props.addInstrumentLinks(querySnapshot.docs.map(doc => doc.data()))
            });

    }

    getYoutubeData = () => {
        const db = firebase.firestore();
        db.collection("recentYoutubeVideos")
            .get()
            .then(querySnapshot => {
                console.log('yt data', querySnapshot.docs.map(doc => doc.data()))
                this.props.addYTData(querySnapshot.docs.map(doc => doc.data()))
            });
    }

    getProductLiks = () => {
        const db = firebase.firestore();
        db.collection("productLinks")
            .get()
            .then(querySnapshot => {
                console.log('productLinks', querySnapshot.docs.map(doc => doc.data()))
                this.props.addProductLinks(querySnapshot.docs.map(doc => doc.data()))
            });
    }

    render() {
        const isMobile = window.innerWidth;

        const { homeData, youtubeData, productLinks, cameraLinks, instrumentsLinks, decorLinks, featuredBlogs, myBooks, myBags, myPainitingsShows, myBnwShows } = this.props.user

        return (


            <div className={styles.centered}>
                <div className={styles.subContainer}>
                    <div className={styles.headertitlecontainer}>
                        <div className={styles.cardcontainer} >
                            <div className={styles.topcard}>
                                <img src={require('../Images/paddy57.jpg')} />
                            </div>
                        </div>
                        <div className={styles.headertitlecontainer
                        } >

                            <h1 style={{ color: '#615a51', fontFamily: "Helvetica Neue", fontSize: 14, margin: 0, fontWeight: 'bold', }}>PRADNYANAND POHARE</h1>
                            <h3 style={{ color: '#615a51', fontFamily: "Helvetica Neue", fontSize: 10, margin: 0, marginTop: 5, fontWeight: 'bold', }}>@mrkajukatli</h3>

                            <h3 style={{ color: '#615a51', fontFamily: "Helvetica Neue", fontSize: 12, margin: 0, marginTop: 25, fontWeight: 'bold', }}>
                                🕯🖥🪴
                            </h3>
                            <h3 style={{ color: '#615a51', fontFamily: "Helvetica Neue", fontSize: 12, margin: 0, marginTop: 5, fontWeight: 'bold', }}>
                                Welcome! This is where you will get to know
                            </h3>
                            <h3 style={{ color: '#615a51', fontFamily: "Helvetica Neue", fontSize: 12, margin: 0, marginTop: 2, fontWeight: 'bold', }}>
                                about my work, desk setup and projects.
                            </h3>
                            <h3 style={{ color: '#615a51', fontFamily: "Helvetica Neue", fontSize: 12, margin: 0, marginTop: 10, fontWeight: 'bold', }}>
                                ✨ Keep Scrolling! ✨
                            </h3>
                            <Lottie
                                style={{ height: 100, width: 100, marginTop: 0, }}
                                options={{
                                    animationData: scroll
                                }}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>

                            <div className={styles.aboveFooter}>
                                <a target="_blank" href="https://www.instagram.com/hereispaddy/">  <i className="fa fa-instagram"></i></a>
                                <a target="_blank" href="https://twitter.com/hereispaddy">  <i className="fa fa-twitter"></i></a>
                                <a target="_blank" href="http://github.com/paddy57"><i className="fa fa-github"></i></a>
                                <a target="_blank" href="https://dev.to/paddy57"><i className="fa fa-code"></i></a>
                            </div>

                        </div>
                        <div class={styles.flexContainer}>
                            <h3 class={styles.unserlineWhite} onClick={() => this.props.history.push('/work')}>WORK</h3>
                            <h2 class={styles.unserlineSlash}>/</h2>
                            <h3 class={styles.unserlineWhite} onClick={() => this.props.history.push('/blogs')}>BLOG</h3>
                            {/* <h2 class={styles.unserlineSlash}>/</h2> */}
                            {/* <h3 class={styles.unserlineWhite} onClick={() => this.props.history.push('/about')}>ABOUT ME</h3> */}
                            {/* <h2 class={styles.unserlineSlash}>/</h2>
                            <h3 class={styles.unserlineWhite} onClick={() => this.props.history.push('/gallery')} >GALLERY</h3> */}
                            <h2 class={styles.unserlineSlash}>/</h2>
                            <a target="_blank" class={styles.unserlineWhite} href={"https://pradnyanand.substack.com/"}>NEWSLETTER</a>
                        </div>
                    </div>

                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>Latest News | Updates</h2>
                    </div>

                    <div className={styles.wrapper}>
                        {homeData && homeData.map(function (item, index) {
                            return (
                                <CardItem
                                    description={item.description}
                                    imageUrl={item.imageUrl}
                                    url={item.url}
                                />
                            )
                        })}
                    </div>
                    <Lottie
                        style={{ height: 80, width: 200, }}
                        options={{
                            animationData: scrollhorizontal
                        }}
                    />

                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>Checkout My Recent YouTube Videos</h2>
                        <div className={styles.youtubecontainer}>
                            {youtubeData && youtubeData.map(function (item, index) {
                                return (
                                    <div className={styles.item} >
                                        <iframe
                                            src={item.ytUrl}
                                            frameborder="0"
                                            allow="autoplay; encrypted-media"
                                            allowfullscreen
                                            title="video"
                                            width='100%'
                                            height='250'
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>My Written Words ✏️</h2>
                        <h3 className={styles.subTitle}>Experiences, learnings and some funny stuff you will get to see in my blogs</h3>
                    </div>

                    <div className={styles.wrapperproduct}>
                        {featuredBlogs && featuredBlogs.map(function (item, index) {
                            return (
                                <ProductCardItemAlt
                                    productName={item.blogName}
                                    productDescription={item.blogDescription}
                                    productImage={item.blogImage}
                                    productUrl={item.blogUrl}
                                />
                            )
                        })}
                    </div>



                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>My Dream Desk Setup Accessories 🖥</h2>
                        <h3 className={styles.subTitle}>I have been building my dream desk setup and following products I have tried so far. Do check out them!</h3>


                    </div>
                    <div className={styles.wrapperproduct}>
                        {productLinks && productLinks.map(function (item, index) {
                            return (
                                <ProductCardItem
                                    productName={item.productName}
                                    productDescription={item.productDescription}
                                    productImage={item.productImage}
                                    productUrl={item.productUrl}
                                />
                            )
                        })}
                    </div>


                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>My Black And Whites</h2>
                        <h3 className={styles.subTitle}>Life generally looks more colourful but its actually black and white, like a art.</h3>
                    </div>

                    <div className={styles.wrappecamerarproduct}>
                        {myBnwShows && myBnwShows.map(function (item, index) {
                            return (
                                <ImageItem
                                    redirectUrl={item.redirectUrl}
                                    imgUrl={item.imgUrl}
                                    isClickable={item.isClickable}
                                />
                            )
                        })}
                    </div>
                    <Lottie
                        style={{ height: 80, width: 200, }}
                        options={{
                            animationData: scrollhorizontal
                        }}
                    />

                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>My Camera Gears 📸</h2>
                        <h3 className={styles.subTitle}>These things helps me to make my youtube videos.</h3>
                    </div>

                    <div className={styles.wrappecamerarproduct}>
                        {cameraLinks && cameraLinks.map(function (item, index) {
                            return (
                                <HorizontalCard
                                    productName={item.productName}
                                    productDescription={item.productDescription}
                                    productImage={item.productImage}
                                    productUrl={item.productUrl}
                                />
                            )
                        })}
                    </div>
                    <Lottie
                        style={{ height: 80, width: 200, }}
                        options={{
                            animationData: scrollhorizontal
                        }}
                    />



                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>Things I'm trying to learn!</h2>
                    </div>

                    <div className={styles.wrappecamerarproduct}>
                        {instrumentsLinks && instrumentsLinks.map(function (item, index) {
                            return (
                                <HorizontalCard
                                    productName={item.productName}
                                    productDescription={item.productDescription}
                                    productImage={item.productImage}
                                    productUrl={item.productUrl}
                                    videoUrl={item.videoUrl}

                                />
                            )
                        })}
                    </div>
                    <Lottie
                        style={{ height: 80, width: 200, }}
                        options={{
                            animationData: scrollhorizontal
                        }}
                    />

                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>My paintings</h2>
                        <h3 className={styles.subTitle}>Paintings are real stress buster for me.</h3>
                    </div>

                    <div className={styles.wrappecamerarproduct}>
                        {myPainitingsShows && myPainitingsShows.map(function (item, index) {
                            return (
                                <ImageItem
                                    redirectUrl={item.redirectUrl}
                                    imgUrl={item.imgUrl}
                                    isClickable={item.isClickable}
                                />
                            )
                        })}
                    </div>
                    <Lottie
                        style={{ height: 80, width: 200, }}
                        options={{
                            animationData: scrollhorizontal
                        }}
                    />

                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>My Desk Setup Decors!</h2>
                    </div>

                    <div className={styles.wrappecamerarproduct}>
                        {decorLinks && decorLinks.map(function (item, index) {
                            return (
                                <HorizontalCard
                                    productName={item.productName}
                                    productDescription={item.productDescription}
                                    productImage={item.productImage}
                                    productUrl={item.productUrl}
                                    videoUrl={item.videoUrl}

                                />
                            )
                        })}
                    </div>
                    <Lottie
                        style={{ height: 80, width: 200, }}
                        options={{
                            animationData: scrollhorizontal
                        }}
                    />

                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>Graphics Designing</h2>
                    </div>


                    <div onClick={() => window.open('https://lottiefiles.com/paddy', "_blank")} className={styles.cameraproduct}>
                        <div className={styles.productleftcontainerheight}>
                            <Lottie
                                style={{ height: 230, width: 180, marginTop: 20 }}
                                options={{
                                    animationData: loading
                                }}
                            />
                        </div>
                        <div className={styles.productrightcontainerhright}>
                            <h3 className={styles.productnamealt}>
                                My Lottie Animations
                            </h3>
                            <h3 className={styles.productdescriptionalt}>
                                LottieFiles is amazing library where you can get all sort of animations to use in your project and that too free. Checkout some of mine too.
                            </h3>

                        </div>


                    </div>

                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>What's In My Bag</h2>
                    </div>

                    <div className={styles.wrappecamerarproduct}>
                        {myBags && myBags.map(function (item, index) {
                            return (
                                <HorizontalCard
                                    productName={item.productName}
                                    productDescription={item.productDescription}
                                    productImage={item.productImage}
                                    productUrl={item.productUrl}
                                    videoUrl={item.videoUrl}

                                />
                            )
                        })}
                    </div>
                    <Lottie
                        style={{ height: 80, width: 200, }}
                        options={{
                            animationData: scrollhorizontal
                        }}
                    />
                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>Books From My Collection</h2>
                        <h3 className={styles.subTitle}>I'm never been a reader but following books I have read, I enjoyed them.</h3>
                    </div>

                    <div className={styles.wrappecamerarproduct}>
                        {myBooks && myBooks.map(function (item, index) {
                            return (
                                <HorizontalCard
                                    productName={item.productName}
                                    productDescription={item.productDescription}
                                    productImage={item.productImage}
                                    productUrl={item.productUrl}
                                    videoUrl={item.videoUrl}

                                />
                            )
                        })}
                    </div>
                    <Lottie
                        style={{ height: 80, width: 200, }}
                        options={{
                            animationData: scrollhorizontal
                        }}
                    />

                    <div className={styles.headertitlecontainer}>
                        <h2 className={styles.headertitle}>About Paddy</h2>
                        <h3 className={styles.subTitleBio}>Hi, I'm Pradnyanand, sometimes its hard to pronounce so people calls me paddy.</h3>
                        <h3 className={styles.subTitleBio}>I have been building my dream desk setup since last two years, which I have been sharing with internet.</h3>
                        <h3 className={styles.subTitleBio}>I'm a frontend developer, like to draw out design and build them to reality. I'm also looking to make my hands dirty in data driven framework in future.</h3>
                        <h3 className={styles.subTitleBio}>Apart from my job I like to travel my country and explore different places and meet people and that experience with other by making youtube videos.</h3>


                        <h3 className={styles.subTitleBio}>Check out the product links above if you're interested in any of the items from my desk setup! By clicking and purchasing from these affiliate links, I get a small commission, This will help me toward my goal.</h3>
                    </div>
                </div>
            </div >
        )
    }
}



export default connect((app) => ({ user: app.user }), (dispatchEvent) => ({
    addHomeData: (data) => dispatchEvent(addHomeData(data)),
    addYTData: (data) => dispatchEvent(addYTData(data)),
    addProductLinks: (data) => dispatchEvent(addProductLinks(data)),
    addCameraLinks: (data) => dispatchEvent(addCameraLinks(data)),
    addInstrumentLinks: (data) => dispatchEvent(addInstrumentLinks(data)),
    addDecorLinks: (data) => dispatchEvent(addDecorLinks(data)),
    addFeaturedBlogs: (data) => dispatchEvent(addFeaturedBlogs(data)),
    addMyBag: (data) => dispatchEvent(addMyBag(data)),
    addMyBooks: (data) => dispatchEvent(addMyBooks(data)),
    addBnwPhotosShow: (data) => dispatchEvent(addBnwPhotosShow(data)),
    addPaintingsShow: (data) => dispatchEvent(addPaintingsShow(data)),

}))(Home)