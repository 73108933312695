import React from "react";
import styles from './BlogsMore.module.css'

export default function CustomForm({ status, message, onValidated, isMobile }) {
    let email, name;
    const submit = () =>
        email &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
        });

    return (
        <div
            style={{
                backgroundColor: "transparent",
                borderStyle: 'solid',
                borderColor: '#FFFFFF',
                borderWidth: 1,
                borderRadius: 7,
                padding: 10,
                display: "inline-block",
                width: isMobile <= 500 ? '95%' : '95%',
                marginTop: 30

            }}
        >

            <p style={{ fontSize: 22, color: '#000000', fontFamily: "Helvetica Neue", fontWeight: 'bold', textAlign: 'center', margin: 0 }}>Subscribe to my Blogs</p>
            <p style={{ fontSize: 12, fontFamily: "Helvetica Neue", color: '#000000', textAlign: 'center', margin: 0, marginBottom: 10 }}>Get notified whenever I blog something.</p>
            <div style={{ flex: 1, flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                <input
                    style={{ fontSize: 16, padding: 5, width: isMobile <= 500 ? '55%' : '75%', backgroundColor: 'transparent', marginBottom: 5, color: '#000000', borderRadius: 5, borderWidth: 1, borderColor: '#000000' }}
                    ref={node => (email = node)}
                    type="email"
                    placeholder="Your email address"
                />

                <button className={styles.buttonSub} style={{ marginLeft: 10, color: '#000000', fontFamily: "Helvetica Neue" }} onClick={submit}>Submit</button>
            </div>
            {status === "sending" && <div style={{ fontSize: 12, color: "#000000", fontFamily: "Helvetica Neue", }}>Subscribing.</div>}
            {status === "error" && <div style={{ fontSize: 12, color: "#000000", fontFamily: "Helvetica Neue", }}>Something went wrong.</div>}
            {status === "success" && <div style={{ fontSize: 12, color: "#000000", fontFamily: "Helvetica Neue", }}>Thank you for subscribing.</div>}

        </div>
    );
};