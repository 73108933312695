import React, { Component } from 'react'
import styles from '../Home/Home.module.css'

const ProductCardItemAlt = (props) => {
    return <div onClick={() => window.open(props.productUrl, "_blank")} className={styles.cameraproduct}>
        <div className={styles.productleftcontainerheight}>
            {/* todop */}
            {/* <img src={props.productImage} style={{ height: 120, width: 100, borderRadius: 10, objectFit: 'cover', alignSelf: 'center', marginLeft: 20 }} /> */}
            <img src={props.productImage} className={styles.blogimg} />


        </div>
        <div className={styles.productrightcontainerhright}>
            <h3 className={styles.blognamealt}>
                {props.productName}
            </h3>
            <h3 className={styles.blogdescriptionalt}>
                {props.productDescription}
            </h3>
        </div>
    </div>
}


export default ProductCardItemAlt;