export const ADD_HOME_DATA = 'ADD_HOME_DATA'
export const ADD_WORK_DATA = 'ADD_WORK_DATA'
export const ADD_BLOG_DATA = 'ADD_BLOG_DATA'
export const ADD_BLOG_DATA_PERSONAL = 'ADD_BLOG_DATA_PERSONAL'
export const IS_ON_PROFESSIONAL = 'IS_ON_PROFESSIONAL'
export const ADD_GALLERY_COLOR = 'ADD_GALLERY_COLOR'
export const ADD_GALLERY_BLACK = 'ADD_GALLERY_BLACK'
export const ADD_GALLERY_PAINT = 'ADD_GALLERY_PAINT'
export const CURRENT_GALLERY_NUM = 'CURRENT_GALLERY_NUM'
export const ADD_YT_DATA = 'ADD_YT_DATA'
export const ADD_PRODUCT_LINKS = 'ADD_PRODUCT_LINKS'
export const ADD_CAMERA_LINKS = 'ADD_CAMERA_LINKS'
export const ADD_INSTRUMENTS_LINKS = 'ADD_INSTRUMENTS_LINKS'
export const ADD_DECOR_LINKS = 'ADD_DECOR_LINKS'
export const ADD_FEATURED_BLOGS = 'ADD_FEATURED_BLOGS'
export const ADD_MY_BAGS = 'ADD_MY_BAGS'
export const ADD_MY_BOOKS = 'ADD_MY_BOOKS'
export const ADD_BNW_SHOW = 'ADD_BNW_SHOW'
export const ADD_PAINTINGS_SHOW = 'ADD_PAINTINGS_SHOW'




function addBnwPhotosShow(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_BNW_SHOW,
        payload
      });
    })();
  }
}

function addPaintingsShow(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_PAINTINGS_SHOW,
        payload
      });
    })();
  }
}
function addMyBag(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_MY_BAGS,
        payload
      });
    })();
  }
}
function addMyBooks(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_MY_BOOKS,
        payload
      });
    })();
  }
}

function addHomeData(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_HOME_DATA,
        payload
      });
    })();
  }
}

function addFeaturedBlogs(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_FEATURED_BLOGS,
        payload
      });
    })();
  }
}



function addDecorLinks(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_DECOR_LINKS,
        payload
      });
    })();
  }
}

function addInstrumentLinks(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_INSTRUMENTS_LINKS,
        payload
      });
    })();
  }
}

function addCameraLinks(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_CAMERA_LINKS,
        payload
      });
    })();
  }
}




function addYTData(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_YT_DATA,
        payload
      });
    })();
  }
}


function addProductLinks(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_PRODUCT_LINKS,
        payload
      });
    })();
  }
}

function addWorkData(payload) {
  console.log('addWorkData', payload)
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: ADD_WORK_DATA,
        payload
      });
    })();
  }
}

function isOnProfessionalAction(payload) {
  console.log('isOnProfessionalAction', payload)
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: IS_ON_PROFESSIONAL,
        payload
      });
    })();
  }
}



function addBlogData(payload, payloadId, bool) {
  if (bool) {
    return (dispatch) => {
      return (async () => {
        dispatch({
          type: ADD_BLOG_DATA_PERSONAL,
          payload,
          payloadId
        });
      })();
    }
  } else {
    return (dispatch) => {
      return (async () => {
        dispatch({
          type: ADD_BLOG_DATA,
          payload,
          payloadId
        });
      })();
    }
  }
}

function addGalleryData(payload, number) {
  if (number === 1) {
    return (dispatch) => {
      return (async () => {
        dispatch({
          type: ADD_GALLERY_COLOR,
          payload,
        });
      })();
    }
  } else if (number === 2) {
    return (dispatch) => {
      return (async () => {
        dispatch({
          type: ADD_GALLERY_BLACK,
          payload,
        });
      })();
    }
  } else {
    return (dispatch) => {
      return (async () => {
        dispatch({
          type: ADD_GALLERY_PAINT,
          payload,
        });
      })();
    }
  }
}

function setCurrentGalleryNumber(payload) {
  return (dispatch) => {
    return (async () => {
      dispatch({
        type: CURRENT_GALLERY_NUM,
        payload
      });
    })();
  }
}







export { addHomeData, addWorkData, addBlogData, isOnProfessionalAction, addGalleryData, setCurrentGalleryNumber, addYTData, addProductLinks, addCameraLinks, addInstrumentLinks, addDecorLinks, addFeaturedBlogs, addMyBooks, addMyBag, addPaintingsShow, addBnwPhotosShow }