import React, { Component } from 'react'
import TextLoop from "react-text-loop";
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './About.module.css'
import loading from '../Images/loading.json'
import Lottie from 'lottie-react-web'
import Notifications, { notify } from 'react-notify-toast';
import firebase from '../Firestore'
import "react-alice-carousel/lib/alice-carousel.css";
import "react-image-gallery/styles/css/image-gallery.css"
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

class About extends Component {

  constructor() {
    super();
    AOS.init();
    this.state = {
      email: '',
      fullname: '',
    }
  }

  componentDidMount() {
    const db = firebase.firestore();
    db.collection("users")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        console.log("firebase", data); // array of cities objects
      });
      firebase.analytics().logEvent('aboutPage')
  }

  goToPage = (pageNumber) => {
    this.reactPageScroller.goToPage(pageNumber);
  }

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  addUser = e => {
    let myColor = { background: 'yellow', text: "#000", fontFamily: "VT323", };
    let myColor2 = { background: 'red', text: "#fff", fontFamily: "VT323", };
    if (this.state.fullname === '' || this.state.email === '') {
      //prevent default stop the page from refreshing
      e.preventDefault();
      notify.show('Please fill all the fields', "custom", 5000, myColor2);
    } else {
      //prevent default stop the page from refreshing
      e.preventDefault();
      const db = firebase.firestore();
      db.settings({
        timestampsInSnapshots: true
      });
      db.collection("users").add({
        fullname: this.state.fullname,
        email: this.state.email
      });
      this.setState({
        fullname: "",
        email: ""
      });

      notify.show('I will contact you buddy!', "custom", 5000, myColor);
    }
  };


  render() {

    let aboutBannar1;
    let aboutBannar2;
    let aboutBannar3;
    let aboutBannar4;
    let aboutBannar5;
    let aboutBannar6;


    const ColoredLine = ({ color }) => (
      <hr
        style={{
          marginTop: 20,
          color: color,
          backgroundColor: color,
          height: 0.02
        }}
      />
    );

    aboutBannar1 = (
      <Fade style={{ margin: 0 }}>
        <h3 className={styles.answerText}>Well, He prefer his name, many people struggle while pronoucing, so he suggest them to call paddy.</h3>
      </Fade>
    )

    aboutBannar2 = (
      <Fade>
        <h3 className={styles.answerText}>Yess, He loves to make videos, you know what he also have a <span style={{ color: 'skyblue', fontSize: 14, margin: 0, padding: 0 }}> <a target="_blank" href="https://www.youtube.com/channel/UCRf7XY1YSHqtVYfvcuerkhg?view_as=subscriber">youtube channel</a> </span> with enough video to watch. In future he wants to continue to make videos apart from his job.</h3>
        {/* <iframe width="'105 rem'" height="'85 rem'" src="https://www.youtube.com/embed/mx4A5WZbvv0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
      </Fade>
    )

    aboutBannar3 = (
      <Fade>
        <h3 className={styles.answerText}>Everyones faviorate<span style={{ marginTop: 0, padding: 0, color: 'skyblue', fontSize: 14 }}><a target="_blank" href="https://www.youtube.com/watch?v=jG7dSXcfVqE"> Casey Niestat</a></span>. You can say he is the god of vlogging. Pradnyanand does not follow him becuase of his great editing skills, he follow him because of his passion towards his work, his life journey, its enough to inspire anyone to maxout their credit card and buy a camera and to make videos.</h3>
      </Fade>
    )

    aboutBannar4 = (
      <Fade>
        <h3 className={styles.answerText}>Haha, I knew you will ask me this one, Yes he likes to paint sometime, its like a stress bruster for him. He has been posting his paintings on his <span style={{ color: '#522482', fontSize: 14 }}><a target="_blank" href="http://instagram.com/colors_by_paddy/" > Instagram.</a></span></h3>
        {/* <Gallery images={photos} /> */}
      </Fade>
    )

    aboutBannar5 = (
      <Fade>
        <h3 className={styles.answerText}>Its like giving back to society. Once Pradnyanand wanted to use animation for one of his project and while exploring he found the <span style={{ color: 'red', fontSize: 14 }}><a target="_blank" href="https://lottiefiles.com/paddy" >Lottie Animation</a></span>, Its a great library. He has made numnber of animation and added them on Lottie website.</h3>
        <Lottie
          style={{ height: 100, width: 150, backgroundColor: 'white' }}
          options={{
            animationData: loading
          }}
        />
      </Fade>
    )

    aboutBannar6 = (
      <Fade>
        <h3 className={styles.answerText}>He is kind of inspired from one of his best friend <span style={{ color: 'skyblue', fontSize: 14, }}> <a target="_blank" href="http://mohitkarekar.com/">Mohit</a></span>. I remember pradnyanand telling me "it will be great way to showcase work to world."</h3>
      </Fade>
    )

    let mobileUI = null;
    const isMobile = window.innerWidth;

    let navigationComp = null;
    navigationComp = (
      <div style={{ marginTop: 40, width: isMobile <= 500 ? '75%' : '55%', }}>
        <div onClick={() => this.props.history.goBack()} style={{ cursor: 'pointer', }}>
          <div style={{ display: 'inline-block' }}>   <FontAwesomeIcon icon={faArrowLeft} style={{ color: '#FFD800', }} />
          </div>
          <div style={{ display: 'inline-block' }}>
            <h1 style={{ fontSize: 22, color: '#FFD800', fontFamily: "Open Sans", marginLeft: 10, fontWeight: 'bold' }}>/About </h1>
          </div>
        </div>
      </div>
    )
    if (isMobile < 600) {
      mobileUI = (
        <div style={{ width: isMobile <= 500 ? '75%' : '55%', padding: 20 }}>
          <h2 style={{ fontFamily: 'Bungee', fontSize: 14, color: 'white', }}>
            Want to {" "}
            <TextLoop interval={2000}>
              <span style={{ fontFamily: 'Bungee', backgroundColor: 'black', fontSize: 14, color: '#FFD800', padding: 5, marginTop: 10 }}>know his hobbies?</span>
              <span style={{ fontFamily: 'Bungee', backgroundColor: 'black', fontSize: 14, color: '#FFD800', padding: 5, marginTop: 10 }}>know who inspire him?</span>
              <span style={{ fontFamily: 'Bungee', backgroundColor: 'black', fontSize: 14, color: '#FFD800', padding: 5, marginTop: 10 }}>Hangout with him?</span>
            </TextLoop>{" "}
          </h2>
          <h3 className={styles.questionText}>What can we call him other than Pradnyanand?</h3>
          {aboutBannar1}
          <ColoredLine color="grey" />
          <h3 className={styles.questionText}>Nice to know that. Everyone has something to do in life, What he want to do other than the job?</h3>
          {aboutBannar2}
          <ColoredLine color="grey" />
          <h3 className={styles.questionText}>YouTube is best thing, So who inspire him to make videos?</h3>
          {aboutBannar3}
          <ColoredLine color="grey" />
          <h3 className={styles.questionText}>I heard that he also makes paintings , is that correct?</h3>
          {aboutBannar4}
          <ColoredLine color="grey" />
          <h3 className={styles.questionText}>Well I didn't know that. I have been following him on his Linkedin page for couple of days,I saw that he is making some animations. Whats the deal there?</h3>
          {aboutBannar5}
          <ColoredLine color="grey" />
          <h3 className={styles.questionText}>Good to know that, how he thought of making this portfolio kind of website?</h3>
          {aboutBannar6}
          <ColoredLine color="#FFD800" />
          <div style={{ marginBottom: 10 }}>

            <h3 style={{
              textAlign: 'left',
              fontFamily: 'Open Sans',
              fontSize: 14,
              color: 'white'
            }}>Thank you for hanging out with me, I think you came to know somethings about Pradnyanand. If you want to have conversation with him, please fill the below form, Byee. </h3></div>
          <form class={styles.form} onSubmit={this.addUser}>
            <input
              style={{ fontSize: 18, width: '40%' }}
              class={styles.forminput}
              type="text"
              name="fullname"
              placeholder="Name"
              onChange={this.updateInput}
              value={this.state.fullname}
            />
            <input
              style={{ fontSize: 18, width: '40%' }}
              class={styles.forminput}
              type="email"
              name="email"
              placeholder="Email"
              onChange={this.updateInput}
              value={this.state.email}
            />
            <button style={{ fontSize: 15 }} class={styles.formbutton} type="submit">Submit</button>
            <Notifications />
          </form>

        </div>
       

      )
    } else {

      mobileUI = (
        <div style={{ width: isMobile <= 500 ? '75%' : '55%', padding: 20 }}>
          <h2 style={{ fontFamily: 'Bungee', fontSize: 23, color: 'white', }}>
            Want to {" "}
            <TextLoop interval={2000}>
              <span style={{ fontFamily: 'Bungee', backgroundColor: 'black', fontSize: 23, color: '#FFD800', padding: 5, marginTop: 10 }}>Know more about Pradnyanand?</span>
              <span style={{ fontFamily: 'Bungee', backgroundColor: 'black', fontSize: 23, color: '#FFD800', padding: 5, marginTop: 10 }}>know his hobbies?</span>
              <span style={{ fontFamily: 'Bungee', backgroundColor: 'black', fontSize: 23, color: '#FFD800', padding: 5, marginTop: 10 }}>know who inspire him?</span>
              <span style={{ fontFamily: 'Bungee', backgroundColor: 'black', fontSize: 23, color: '#FFD800', padding: 5, marginTop: 10 }}>Hangout with him?</span>
            </TextLoop>{" "}
          </h2>
          <h3 className={styles.questionText}>What can we call him other than Pradnyanand?</h3>
          {aboutBannar1}
          <ColoredLine color="grey" />
          <h3 className={styles.questionText}>Nice to know that. Everyone has something to do in life, What he want to do other than the job?</h3>
          {aboutBannar2}
          <ColoredLine color="grey" />
          <h3 className={styles.questionText}>YouTube is best thing, So who inspire him to make videos?</h3>
          {aboutBannar3}
          <ColoredLine color="grey" />
          <h3 className={styles.questionText}>I heard that he also makes paintings , is that correct?</h3>
          {aboutBannar4}
          <ColoredLine color="grey" />
          <h3 className={styles.questionText}>Well I didn't know that. I have been following him on his Linkedin page for couple of days,I saw that he is making some animations. Whats the deal there?</h3>
          {aboutBannar5}
          <ColoredLine color="grey" />
          <h3 className={styles.questionText}>Good to know that, how he thought of making this portfolio kind of website?</h3>
          {aboutBannar6}

          <ColoredLine color="yellow" />
          <div style={{ marginBottom: 10 }}>

            <h3 style={{
              textAlign: 'left',
              fontFamily: 'Open Sans',
              fontSize: 14,
              color: 'white'
            }}>Thank you for hanging out with me, I think you came to know somethings about Pradnyanand. If you want to have conversation with him, please fill the below form, Byee. </h3></div>
          <form class={styles.form} onSubmit={this.addUser}>
            <input
              style={{ fontSize: 18, width: '40%' }}
              class={styles.forminput}
              type="text"
              name="fullname"
              placeholder="Name"
              onChange={this.updateInput}
              value={this.state.fullname}
            />
            <input
              style={{ fontSize: 18, width: '40%' }}
              class={styles.forminput}
              type="email"
              name="email"
              placeholder="Email"
              onChange={this.updateInput}
              value={this.state.email}
            />
            <button style={{ fontSize: 15 }} class={styles.formbutton} type="submit">Submit</button>
            <Notifications />
          </form>

        </div>

      )
    }
    return (
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        flex: 1,
        width: '100%',
        backgroundColor: '#000000',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }} >
        {navigationComp}
        {mobileUI}
        <div className={styles.footer} >
          <p className={styles.autherName}>© Pradnyanand Milind Pohare</p>
        </div>
      </div>
    )
  }
}

export default About
