import React, { Component } from 'react'
import styles from '../Home/Home.module.css'

const CardItem = (props) => {
    return <div onClick={() => window.open(props.url, "_blank")} className={styles.item}>
        <div style={{ height: '60%', }}>
            <img src={props.imageUrl} style={{ height: 72, width: 72, borderRadius: 10, objectFit: 'cover', alignSelf: 'center' }} />
        </div>
        <div style={{ height: '40%', }}>
            <h3 style={{ color: '#615a51', fontFamily: "Helvetica Neue", fontSize: 12, margin: 0, fontWeight: 'bold', width: 100, marginTop: 5, textAlign: 'center' }}>
                {props.description}
            </h3>

        </div>
    </div>
}

export default CardItem;