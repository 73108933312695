import React, { Component } from 'react'
import styles from '../Home/Home.module.css'
import { faMouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import mouse from '../Animation/mouse.json'
import Lottie from 'lottie-react-web'


const ImageItem = (props) => {
    if (props.isClickable === true) {
        return <div onClick={() => window.open(props.redirectUrl, "_blank")} className={styles.cameraproduct}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {/* <FontAwesomeIcon icon={faMouse} style={{ color: '#000000', fontSize: 30 }} /> */}
                <Lottie
                    style={{ height: 70, width: 70, }}
                    options={{
                        animationData: mouse
                    }}
                />
                <h3 className={styles.blogdescriptionaltShow}>
                    Click here to explore more!
                </h3>
            </div>
        </div>

    } else {
        return <div className={styles.cameraproduct}>
            <div style={{ height: '100%' }}>
                <img src={props.imgUrl} className={styles.productimgShow} />
            </div>
        </div>
    }
}


export default ImageItem;