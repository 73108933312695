import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Redirect } from 'react-router-dom'
import work from './Work/Work'
import about from './About/About'
import home from './Home/Home'
import gallery from './Gallery/GalleryComponet'
import blogs from './Blogs/Blogs'
import blogsMore from './Blogs/BlogsMore'
import admin from './Admin/Admin'
import { Switch, Route } from 'react-router'

class App extends Component {

  componentDidMount() {
    //Remove console from prod
    console.log = function () { };
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route path="/" exact component={home} > <Redirect to="/" /> </Route>
            <Route path="/work" component={work} > <Redirect to="/work" /> </Route>
            <Route path="/about" component={about} />
            <Route path="/gallery" component={gallery} />
            <Route path="/blogs" component={blogs} />
            <Route path="/blogsMore/:blogsname/:id" component={blogsMore} />
            <Route path="/admin" component={admin} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
