import React from 'react'
import styles from './Work.module.css'
import firebase from '../Firestore'
import loadingWork from '../Animation/loaderAnim2.json'
import Lottie from 'lottie-react-web'
import { connect } from 'react-redux'
import { addWorkData } from '../Store/actions/actions'
import BackNavItem from '../layouts/backNavItem'
class Work extends React.Component {

  componentDidMount() {
    if (!this.props.user.isVisitedWork) {
      firebase.analytics().logEvent('workPage')
      this.getWorkData()
    }
  }

  getWorkData() {
    const db = firebase.firestore();
    db.collection("pradnyanandWorks")
      .orderBy('workNumber')
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        this.props.addWorkData(data.reverse())
        console.log("firebase Worl", data);
      });
  }

  render() {
    let mobileUI = null;
    const isMobile = window.innerWidth;
    const { isVisitedWork, workData } = this.props.user
    let navigationComp = null;
    navigationComp = (
      <div style={{ marginTop: 40, width: isMobile <= 500 ? '75%' : '55%', }}>
        <BackNavItem
          headerName={'/Work'}
          goBack={this.props.history.goBack}
        />
      </div>
    )
    const ColoredLine = ({ color }) => (
      <hr
        style={{
          marginTop: 20,
          color: color,
          backgroundColor: color,
          height: 0.02
        }}
      />
    );
    if (!isVisitedWork) {
      mobileUI = (
        <div className={styles.subContainer2}>
          <Lottie
            style={{ height: 330, width: 280, marginTop: 50 }}
            options={{
              animationData: loadingWork
            }}
          />
        </div>
      )
    } else {
      if (isMobile <= 500) {
        mobileUI = (
          <div className={styles.subContainer}>
            {navigationComp}
            <h3 style={{ color: '#000000', margin: 0 }}>You just need to work smart on whatever you are doing.</h3>
            <ColoredLine color="grey" />
            {workData && workData.map(function (item, index) {
              return (
                <div>
                  <p style={{ color: '#000000', fontWeight: 'bold', fontSize: 16 }}>{item.workTitle}</p>
                  <p style={{ color: '#000000', margin: 0, fontFamily: "Helvetica Neue", fontSize: 14 }}>{item.workInfo}</p>
                  <p style={{ color: '#000000', fontFamily: "Helvetica Neue", fontSize: 12, fontWeight: 'bold' }}>{item.stackOne} / {item.stackTwo} / {item.stackThree}</p>
                  <div style={{ display: 'inline-block' }}>
                    <a style={{
                      color: '#000000',
                      fontFamily: 'Roboto Mono',
                      borderBottom: 1, borderBottomColor: '#000000', borderBottomStyle: 'solid',
                    }} target="_blank" href={item.workUrl}>
                      {item.workUrlTitle}
                    </a>
                  </div>
                  <div style={{ display: 'inline-block', marginLeft: 10 }}>
                    {item.workUrl2 && <a target="_blank" style={{
                      color: '#000000',
                      fontFamily: 'Roboto Mono',
                      borderBottom: 1, borderBottomColor: '#000000', borderBottomStyle: 'solid',
                    }} href={item.workUrl2}>
                      {item.workUrlTitle2}
                    </a>}
                  </div>
                  <ColoredLine color="grey" />
                </div>
              )
            })}
            <p className={styles.autherName}>© Pradnyanand Milind Pohare</p>
          </div>
        )
      }
      else {
        mobileUI = (
          <div className={styles.subContainer}>
            {navigationComp}
            <h3 style={{ color: '#000000', margin: 0 }}>You just need to work smart on whatever you are doing.</h3>
            <ColoredLine color="grey" />

            {workData && workData.map(function (item, index) {
              return (
                <div>
                  <p style={{ color: '#000000', fontWeight: 'bold', fontSize: 16, margin: 0 }}>{item.workTitle}</p>
                  <p style={{ color: '#000000', margin: 0, marginTop: 5, fontFamily: "Helvetica Neue", fontSize: 14 }}>{item.workInfo}</p>
                  <p style={{ color: '#000000', fontSize: 12, fontStyle: 'italic', margin: 0, marginTop: 5, fontFamily: "Helvetica Neue", fontWeight: 'bold' }}>{item.stackOne} / {item.stackTwo} / {item.stackThree}</p>
                  <div style={{ display: 'inline-block', margin: 0, marginTop: 5 }}>
                    <a style={{
                      color: '#000000',
                      borderBottom: 1, borderBottomColor: '#FFD800', borderBottomStyle: 'solid', fontSize: 16,
                      fontFamily: 'Roboto Mono'
                    }} target="_blank" href={item.workUrl}>
                      {item.workUrlTitle}
                    </a>
                  </div>
                  <div style={{ display: 'inline-block', marginLeft: 10 }}>
                    {item.workUrl2 && <a style={{
                      color: '#000000',
                      borderBottom: 1, borderBottomColor: '#FFD800', borderBottomStyle: 'solid', fontSize: 16,
                      fontFamily: 'Roboto Mono'
                    }} target="_blank" href={item.workUrl2}>
                      {item.workUrlTitle2}
                    </a>}
                  </div>
                  <ColoredLine color="grey" />
                </div>
              )
            })}
            <p className={styles.autherName}>© Pradnyanand Milind Pohare</p>
          </div>
        )
      }
    }
    return (
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        flex: 1,
        width: '100%',
        backgroundColor: '#f6ede0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }} >
        {mobileUI}
      </div>
    )
  }
}


export default connect((app) => ({ user: app.user }), (dispatchEvent) => ({
  addWorkData: (data) => dispatchEvent(addWorkData(data)),
}))(Work)
