import React, { Component } from 'react'
import "react-alice-carousel/lib/alice-carousel.css";
import "react-image-gallery/styles/css/image-gallery.css"
import firebase from '../Firestore'
import styles from './BlogsMore.module.css'
import 'react-tabs/style/react-tabs.css';
import Lottie from 'lottie-react-web'
import loadingWork from '../Animation/loaderAnim2.json'
import ReactMarkdown from "react-markdown";
import gfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterIcon,
    FacebookIcon
} from "react-share";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import CustomForm from './CustomForm'
import BackNavItem from '../layouts/backNavItem'


class BlogsMore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBlog: false,
            blogsListItem: null,
            blogsComment: null,
            likeBlog: false,
            likeColor: 'grey',
            usercomment: '',
            username: '',
            userimage: '',
            useremail: '',
            userloggedIn: false,
        };
    }

    componentDidMount() {
        console.log('url', process.env.REACT_APP_MAILCHIMP_URL)
        this.getBlogData()
        setTimeout(() => {
            this.setState({
                showBlog: true,
            })
        }, 1000);
        this.checkUserLoggedIn();
    }

    getBlogData = () => {
        if (this.props.location.data !== undefined) {
            console.log('blogsMore iii', this.props.location.data)
            this.getBlogFile(this.props.location.data.blogsname === "1" ? "pradnyanandBlogs" : "pradnyanandPersonalBlogs", this.props.location.data.blogNumber)
            this.setState({
                blogsListItem: this.props.location.data,
                likedCount: this.props.location.data.liked,
                documentId: this.props.location.data.docId,
                blogsFromCollection: this.props.location.data.blogsname

            })
            firebase.analytics().logEvent('BlogPageMore', { blogName: this.props.location.data.header, })
            this.getBlogsComment(this.props.location.data.blogsname, this.props.location.data.docId)

        } else {
            console.log('blogsMore direct', this.props.match.params)
            this.getBlogsData(this.props.match.params.blogsname, this.props.match.params.id)
            this.getBlogsComment(this.props.match.params.blogsname, this.props.match.params.id)
            this.setState({
                blogsFromCollection: this.props.match.params.blogsname
            })
        }
    }

    checkUserLoggedIn = () => {
        const auth = firebase.auth();
        auth.onAuthStateChanged(userAuth => {
            console.log('checkUserLoggedIn', userAuth)
            if (userAuth) {
                this.setState({
                    username: userAuth.displayName,
                    userimage: userAuth.photoURL,
                    useremail: userAuth.email,
                    userloggedIn: true
                })
            } else {
                this.setState({
                    userloggedIn: false
                })
            }
        })
    }

    getBlogsComment = (collection, docId) => {
        var db = firebase.firestore();
        db.collection(collection === "1" ? "pradnyanandBlogs" : "pradnyanandPersonalBlogs")
            .doc(docId)
            .collection("blogComment")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                console.log("blogsMore comment ", data);
                this.setState({
                    blogsComment: data,
                })
            });
    }

    getBlogsData = (collection, docId) => {
        var db = firebase.firestore();
        db.collection(collection === "1" ? "pradnyanandBlogs" : "pradnyanandPersonalBlogs")
            .doc(docId)
            .get()
            .then(doc => {
                const data = doc.data();
                console.log('blogsMore doc id', data, data.liked)
                this.getBlogFile(collection === "1" ? "pradnyanandBlogs" : "pradnyanandPersonalBlogs", data.blogNumber)
                firebase.analytics().logEvent('BlogPageMore', { blogName: data.header, })
                this.setState({
                    blogsListItem: data,
                    likedCount: data.liked,
                    documentId: docId,
                })

            });



    }


    getBlogFile = (typeofblog, blogNumber) => {
        let path = './' + typeofblog + '/' + blogNumber + '.md'
        const pathDir = require(`${path}`);
        fetch(pathDir)
            .then(response => {
                return response.text()
            })
            .then(text => {
                this.setState({
                    markdown: text
                })

            })
    }



    likeBlog = () => {
        this.setState({
            likeColor: this.state.likeBlog ? 'grey' : '#f7484e',
            likeBlog: !this.state.likeBlog,
            likedCount: this.state.likeBlog ? this.state.likedCount - 1 : this.state.likedCount + 1,
        })
        console.log('likeBlog', this.state.blogsFromCollection)
        var db = firebase.firestore();
        db.collection(this.state.blogsFromCollection === "1" ? "pradnyanandBlogs" : "pradnyanandPersonalBlogs").doc(this.state.documentId).update({ liked: this.state.likeBlog ? this.state.likedCount - 1 : this.state.likedCount + 1 });
    }

    submitComment = () => {
        if (this.state.userloggedIn) {
            if (this.state.usercomment === '' || this.state.username === '') {
                console.log('commentSection22', this.state.usercomment, this.state.username)
                this.setState({
                    errormessage: 'Please fill all of the above information'
                })
            } else {
                var db = firebase.firestore();
                var ts = new Date();
                console.log('commentSection2', this.state.username, this.state.usercomment, ts.toDateString())
                db.collection(this.state.blogsFromCollection === "1" ? "pradnyanandBlogs" : "pradnyanandPersonalBlogs").doc(this.state.documentId).collection("blogComment").add({
                    username: this.state.username,
                    usercomment: this.state.usercomment,
                    usercommenttimestamp: ts.toDateString(),
                    userimage: this.state.userimage,
                    useremail: this.state.useremail
                });
                let commentObject = {
                    "username": this.state.username,
                    "usercomment": this.state.usercomment,
                    "usercommenttimestamp": ts.toDateString(),
                    "userimage": this.state.userimage,
                    "useremail": this.state.useremail
                }
                let commentArray = this.state.blogsComment
                commentArray.splice(0, 0, commentObject);
                this.setState({
                    blogsComment: commentArray,
                    usercomment: '',
                    errormessage: null
                })
            }
        } else {
            this.signInWithGoogle();
        }

    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    signInWithGoogle = () => {
        const auth = firebase.auth();
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider);
        auth.onAuthStateChanged(userAuth => {
            console.log('signInUser', userAuth)
            if (userAuth) {
                this.setState({
                    username: userAuth.displayName,
                    userimage: userAuth.photoURL,
                    useremail: userAuth.email
                })
            }
        });
    }

    signout = async () => {
        try {
            await firebase.auth().signOut();
            this.setState({
                usercomment: '',
                username: '',
                userimage: '',
                useremail: '',
                userloggedIn: false,
            })
            // signed out
        } catch (e) {
            // an error
            console.log('firebaseautherror', e)
        }
    }


    render() {
        const { markdown } = this.state;

        let blogsUI = null;
        const isMobile = window.innerWidth;
        const blogsItem = this.state.blogsListItem
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    marginTop: 2,
                    color: color,
                    backgroundColor: color,
                    height: 0.02
                }}
            />
        );

        if (blogsItem !== null) {
            let shareUrl = 'http://pradnyanandpohare.in//BlogsMore/' + this.state.documentId
            let shareQuote = 'Here is a small read from Pradnyanand Pohare about ' + blogsItem.header
            let shareBanner = null;
            shareBanner = (
                <div className={styles.shareContainer}>
                    <FacebookShareButton className={styles.socialMediaButton} url={shareUrl} quote={shareQuote}>
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton className={styles.socialMediaButton} url={shareUrl} quote={shareQuote}>
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <LinkedinShareButton className={styles.socialMediaButton} url={shareUrl} quote={shareQuote}>
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </div>
            )

            blogsUI = (
                <div className={styles.subContainer}>
                    <BackNavItem
                        headerName={'/Blogs/More'}
                        goBack={this.props.history.goBack}
                    />
                    <h1 className={styles.blogHeader}>{blogsItem.header}</h1>
                    <div className={styles.authorDiv}>
                        <img src={require('../Images/paddy57.jpg')} className={styles.authorImg} />
                    </div>
                    <div style={{ display: 'inline-block', marginLeft: 10 }}>
                        <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000000', margin: 0 }}>Pradnyanand Pohare</p>
                        <p style={{ fontSize: 12, fontStyle: 'italic', marginTop: 0, color: '#000000', }}>{this.state.blogsListItem.date}</p>
                    </div>
                    <ReactMarkdown
                        remarkPlugins={[gfm]}
                        rehypePlugins={[rehypeRaw]}
                        children={markdown}
                        components={{
                            img: ({ node, ...props }) => <img style={{ width: '100%', height: 'auto' }} {...props} />,
                            p: ({ node, ...props }) => <p style={{ color: '#000000', fontFamily: 'Helvetica Neue', fontSize: 14 }} {...props} />,
                            h1: ({ node, ...props }) => <h1 style={{ color: '#000000', fontFamily: 'Helvetica Neue', fontSize: 24 }} {...props} />,
                            h2: ({ node, ...props }) => <h2 style={{ color: '#000000', fontFamily: 'Helvetica Neue', fontSize: 16 }} {...props} />,
                            li: ({ node, ...props }) => <li style={{ color: '#000000', fontFamily: 'Helvetica Neue', fontSize: 14 }} {...props} />,
                            ol: ({ node, ...props }) => <ol style={{ color: '#000000', fontFamily: 'Helvetica Neue', fontSize: 14 }} {...props} />,
                            a: ({ node, ...props }) => <a target="_blank" style={{ color: '#000000', fontFamily: 'Helvetica Neue', fontSize: 16, fontWeight: 'bold' }} {...props} />,
                            code({ node, inline, className, children, ...props }) {
                                const match = /language-(\w+)/.exec(className || '')
                                return !inline && match ? (
                                    <SyntaxHighlighter style={dracula} language="javascript" PreTag="div" children={String(children).replace(/\n$/, '')} {...props} />
                                ) : (
                                    <code className={className} {...props} />
                                )
                            },
                            blockquote: ({ node, ...props }) => <blockquote style={{
                                backgroundColor: '#cccccc',
                                borderLeftWidth: 5,
                                borderLeftStyle: 'solid',
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                borderColor: '#000000',
                                padding: 2,
                                paddingLeft: 10,
                                color: '#ffffff',
                                margin: 0,
                                fontFamily: 'Helvetica Neue',
                                fontSize: 16
                            }} {...props} />,
                        }}

                    />
                    <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL}
                        render={({ subscribe, status, message }) => (
                            <div>
                                <CustomForm
                                    status={status}
                                    message={message}
                                    onValidated={formData => subscribe(formData)}
                                    isMobile={isMobile}
                                />
                            </div>
                        )}
                    />
                    <div style={{ width: '100%', marginTop: 30, }}>
                        <p style={{ marginTop: 10, fontWeight: 'bold', color: '#000000', textAlign: 'center', margin: 0 }}>What are your thoughts?</p>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, flexDirection: 'column' }}>
                            <input
                                type='text'
                                name='username'
                                placeholder="Your Name"
                                value={this.state.username}
                                className={styles.input}
                                style={{ fontSize: 16, padding: 5, width: isMobile <= 500 ? '55%' : '95%', backgroundColor: 'transparent', marginBottom: 5, color: '#000000', borderRadius: 5, borderWidth: 1, borderColor: '#000000' }}
                                onChange={this.myChangeHandler}
                            />
                            <input
                                type='text'
                                name='usercomment'
                                value={this.state.usercomment}
                                placeholder="Your thoughts"
                                className={styles.input}
                                style={{ fontSize: 16, padding: 5, width: isMobile <= 500 ? '55%' : '95%', backgroundColor: 'transparent', marginBottom: 5, color: '#000000', borderRadius: 5, borderWidth: 1, borderColor: '#000000' }}
                                onChange={this.myChangeHandler}
                            />

                            {this.state.errormessage != null ? <p style={{ marginTop: 5, color: 'red', fontSize: 10 }}>{this.state.errormessage}*</p> : null}
                            <div style={{ display: 'flex', flexDirection: 'row' }} >
                                <button className={styles.buttonSub} style={{ marginTop: 10 }} onClick={this.submitComment}>{this.state.userloggedIn ? 'Submit comment' : 'Log in to comment'}</button>
                                {this.state.userloggedIn ? <div style={{ display: 'inline-block', }} > <button className={styles.buttonSub} style={{ marginTop: 10, marginLeft: 10 }} onClick={this.signout}>Sign out</button> </div> : null}

                            </div>


                        </div>


                        <div style={{ marginTop: 20 }} className={styles.flexContainerComment} >
                            {this.state.blogsComment !== null ? this.state.blogsComment.map(function (item, index) {
                                return (
                                    <div style={{ flex: 1, marginLeft: 5, flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <img src={item.userimage} className={styles.userComment} />
                                            <div style={{ marginLeft: 5, flexDirection: 'column' }}>
                                                <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000000', fontFamily: 'Helvetica Neue', margin: 0 }}>{item.username}</p>
                                                <p style={{ fontSize: 10, fontStyle: 'italic', color: '#000000', fontFamily: 'Helvetica Neue', margin: 0 }}>{item.usercommenttimestamp}</p>
                                            </div>
                                        </div>
                                        <p style={{ fontSize: 14, marginTop: 5, color: '#000000', fontFamily: 'Helvetica Neue' }}>{item.usercomment}</p>
                                        <ColoredLine color="grey" />
                                    </div>
                                )
                            }) : null}
                        </div>
                        {shareBanner}
                    </div>
                    <p className={styles.autherName}>© Pradnyanand Milind Pohare</p>
                </div >
            )
        }

        let loadingUI = null
        loadingUI = (
            <div className={styles.subContainer2}>
                <Lottie
                    style={{ height: 330, width: 280, marginTop: 50 }}
                    options={{
                        animationData: loadingWork
                    }}
                />
            </div>
        )

        return (
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                flex: 1,
                width: '100%',
                backgroundColor: '#f6ede0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }} >
                {this.state.showBlog ? blogsUI : loadingUI}
            </div>

        )
    }
}

export default BlogsMore