import React, { Component } from 'react'
import "react-alice-carousel/lib/alice-carousel.css";
import "react-image-gallery/styles/css/image-gallery.css"
import firebase from '../Firestore'
import styles from './Admin.module.css'
import 'react-tabs/style/react-tabs.css';
import Notifications, { notify } from 'react-notify-toast';
const PERSONAL = 'personal'
const TECHNICAL = 'technical'

let myColor = { background: 'yellow', text: "#000", fontFamily: "VT323", };

const inputFiled = {
    fontSize: 16, padding: 5, backgroundColor: 'transparent', marginTop: 10, color: 'white', borderRadius: 1, borderWidth: 1, borderColor: 'white', fontFamily: 'Roboto Mono', marginLeft: 10, width: '100%'
}

const stylesSheet = {
    headerText: { fontSize: 14, marginTop: 5, fontWeight: 'bold', color: 'yellow', fontFamily: 'Roboto Mono', marginLeft: 15 },
    headerTextValue: { fontSize: 14, marginTop: 5, fontWeight: 'normal', color: 'white', fontFamily: 'Roboto Mono', }
}

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBlog: false,
            workList: null,
            blogsListItem: null,
            blogsComment: null,
            personalBlogList: null,
            likeBlog: false,
            likeColor: 'grey',
            usercomment: '',
            username: '',
            userimage: '',
            useremail: '',
            userloggedIn: false,
            workerrormessage: null,

            workNumber: '',
            workTitle: '',
            workInfo: '',
            workUrl: '',
            stackOne: '',
            stackTwo: '',
            stackThree: '',
            workUrlTitle: '',
            blogerrormessagePersonal: null,
            blogerrormessageTech: null,

            blogIntro: '',
            blogIntroPersonal: '',
            blogNumber: '',
            blogNumberPersonal: '',
            blogDate: '',
            blogDatePersonal: '',
            blogDescription: '',
            blogDescriptionPersonal: '',
            blogHeader: '',
            blogHeaderPersonal: '',
            blogImage: '',
            blogImagePersonal: '',
            blogLiked: '',
            blogLikedPersonal: '',
            isAdmin: false,
            adminPassword: '',
            adminEmail: '',
            adminError: null,

            appUploadName: '',
            appUploadUrl: '',
            newThing: '',
            recentBlogUrl: '',
            workingWith: '',
            workingWithUrl: '',
            youTubeTitle: '',
            youTubeTitleUrl: '',



        };
    }

    componentDidMount() {
        this.getBlogsData()
        this.getPersonalBlogData()
        setTimeout(() => {
            this.setState({
                showBlog: true,
            })
        }, 1000);
        this.getWorkData()
        this.getStatus()
    }


    signInAdmin = () => {
        firebase
            .auth()
            .signInWithEmailAndPassword(this.state.adminEmail, this.state.adminPassword)
            .then(res => {
                console.log('signInAdmin', res)
                this.setState({
                    isAdmin: true,
                    adminError: null
                })
                firebase.auth().setLoggedIn(true);
                notify.show('Welcome Back, Paddy', "custom", 5000, myColor);

            })
            .catch(e => {
                console.log('signInAdmin2', e.message)
                this.setState({
                    adminError: e.message
                })
            });
    }




    getWorkData = () => {
        const db = firebase.firestore();
        db.collection("pradnyanandWorks")
            .orderBy('workNumber')
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                const dataId = querySnapshot.docs.map(doc => doc.id);
                setTimeout(() => {
                    this.setState({
                        workList: data.reverse(),
                        workDocumentId: dataId.reverse(),
                    })
                }, 1000);
                console.log("firebase blog work", dataId);
            });
    }

    getBlogsData = () => {
        const db = firebase.firestore();
        db.collection("pradnyanandBlogs")
            .orderBy('blogNumber')
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                const dataId = querySnapshot.docs.map(doc => doc.id);
                setTimeout(() => {
                    this.setState({
                        blogList: data.reverse(),
                        blogDocumentId: dataId.reverse(),
                        loadingBlog: false
                    })
                }, 1000);
                console.log("firebase blog", dataId);
            });
    }

    getPersonalBlogData = () => {
        const db = firebase.firestore();
        db.collection("pradnyanandPersonalBlogs")
            .orderBy('blogNumber')
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                const dataId = querySnapshot.docs.map(doc => doc.id);
                setTimeout(() => {
                    this.setState({
                        personalBlogList: data.reverse(),
                        perosonalblogDocumentId: dataId.reverse(),
                        loadingBlog: false
                    })
                }, 1000);
                console.log("firebase blog", dataId);
            });
    }

    getStatus = () => {
        const db = firebase.firestore();
        db.collection("pradnyanandStatus")
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                const dataId = querySnapshot.docs.map(doc => doc.id);
                let dataRes = data && data[0]
                setTimeout(() => {
                    this.setState({
                        statusDocumentId: dataId[0],
                        appUploadName: dataRes.appUploadName,
                        appUploadUrl: dataRes.appUploadUrl,
                        newThing: dataRes.newThing,
                        recentBlogUrl: dataRes.recentBlogUrl,
                        workingWith: dataRes.workingWith,
                        workingWithUrl: dataRes.workingWithUrl,
                        youTubeTitle: dataRes.youTubeTitle,
                        youTubeTitleUrl: dataRes.youTubeTitleUrl,
                    })
                }, 1000);
                console.log("firebase status", dataId, data);
            });
    }

    updateStatus = () => {
        const docRef = firebase.firestore().collection('pradnyanandStatus').doc(this.state.statusDocumentId)
        docRef.update({
            appUploadName: this.state.appUploadName,
            appUploadUrl: this.state.appUploadUrl,
            newThing: this.state.newThing,
            recentBlogUrl: this.state.recentBlogUrl,
            workingWith: this.state.workingWith,
            workingWithUrl: this.state.workingWithUrl,
            youTubeTitle: this.state.youTubeTitle,
            youTubeTitleUrl: this.state.youTubeTitleUrl,
        })
        notify.show('Status Updated!', "custom", 5000, myColor);
    }

    deleteWork = (number, index) => {
        console.log("delete work", number, index);
        var db = firebase.firestore();
        var work_query = db.collection('pradnyanandWorks').where('workNumber', '==', number);
        work_query.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                doc.ref.delete();
            });
        });
        let workArray = this.state.workList
        workArray.splice(index, 1);
        this.setState({
            workList: workArray
        })
        notify.show('Work Deleted!', "custom", 5000, myColor);
    }



    submitWork = () => {
        if (this.state.workNumber === '' || this.state.workTitle === '' || this.state.workInfo === '') {
            console.log('submitWORK', 'Please fill all of the above information')
            this.setState({
                workerrormessage: 'Please fill all of the above information'
            })
        }

        else {
            var db = firebase.firestore();
            db.collection("pradnyanandWorks").add({
                workNumber: this.state.workNumber,
                workTitle: this.state.workTitle,
                workInfo: this.state.workInfo,
                workUrlTitle: this.state.workUrlTitle,
                workUrl: this.state.workUrl,
                stackOne: this.state.stackOne,
                stackTwo: this.state.stackTwo,
                stackThree: this.state.stackThree,
                workImage: this.state.workImage
            });
            let workObj = {
                "workNumber": this.state.workNumber,
                "workTitle": this.state.workTitle,
                "workInfo": this.state.workInfo,
                "workUrlTitle": this.state.workUrlTitle,
                "workUrl": this.state.workUrl,
                "stackOne": this.state.stackOne,
                "stackTwo": this.state.stackTwo,
                "stackThree": this.state.stackThree,
                "workImage": this.state.workImage
            }
            let workArray = this.state.workList
            workArray.splice(0, 0, workObj);
            this.setState({
                workList: workArray,
                workNumber: '',
                workTitle: '',
                workInfo: '',
                workUrl: '',
                stackOne: '',
                stackTwo: '',
                stackThree: '',
                workUrlTitle: '',
                workImage: '',
                workerrormessage: null

            })
            notify.show('Work Submitted!', "custom", 5000, myColor);
        }
    }

    fireSubBlogData = (typeOfBlog) => {
        var db = firebase.firestore();
        db.collection(typeOfBlog === TECHNICAL ? "pradnyanandBlogs" : "pradnyanandPersonalBlogs").add({
            blogIntro: typeOfBlog === TECHNICAL ? this.state.blogIntro : this.state.blogIntroPersonal,
            blogNumber: typeOfBlog === TECHNICAL ? parseInt(this.state.blogNumber) : parseInt(this.state.blogNumberPersonal),
            date: typeOfBlog === TECHNICAL ? this.state.blogDate : this.state.blogDatePersonal,
            description: typeOfBlog === TECHNICAL ? this.state.blogDescription : this.state.blogDescriptionPersonal,
            header: typeOfBlog === TECHNICAL ? this.state.blogHeader : this.state.blogHeaderPersonal,
            image: typeOfBlog === TECHNICAL ? this.state.blogImage : this.state.blogImagePersonal,
            liked: typeOfBlog === TECHNICAL ? this.state.blogLiked : this.state.blogLikedPersonal,

        });
        let workObj = {
            "blogIntro": typeOfBlog === TECHNICAL ? this.state.blogIntro : this.state.blogIntroPersonal,
            "blogNumber": typeOfBlog === TECHNICAL ? parseInt(this.state.blogNumber) : parseInt(this.state.blogNumberPersonal),
            "date": typeOfBlog === TECHNICAL ? this.state.blogDate : this.state.blogDatePersonal,
            "description": typeOfBlog === TECHNICAL ? this.state.blogDescription : this.state.blogDescriptionPersonal,
            "header": typeOfBlog === TECHNICAL ? this.state.blogHeader : this.state.blogHeaderPersonal,
            "image": typeOfBlog === TECHNICAL ? this.state.blogImage : this.state.blogImagePersonal,
            "liked": typeOfBlog === TECHNICAL ? this.state.blogLiked : this.state.blogLikedPersonal,
        }
        let blogArray = typeOfBlog === TECHNICAL ? this.state.blogList : this.state.personalBlogList
        blogArray.splice(0, 0, workObj);


        let blogIntro = typeOfBlog === TECHNICAL ? 'blogIntro' : 'blogIntroPersonal';
        let blogNumber = typeOfBlog === TECHNICAL ? 'blogNumber' : 'blogNumberPersonal';
        let date = typeOfBlog === TECHNICAL ? 'blogDate' : 'blogDatePersonal';
        let description = typeOfBlog === TECHNICAL ? 'blogDescription' : 'blogDescriptionPersonal';
        let header = typeOfBlog === TECHNICAL ? 'blogHeader' : 'blogHeaderPersonal';
        let image = typeOfBlog === TECHNICAL ? 'blogImage' : 'blogImagePersonal';
        let liked = typeOfBlog === TECHNICAL ? 'blogLiked' : 'blogLikedPersonal';
        let blogList = typeOfBlog === TECHNICAL ? 'blogList' : 'personalBlogList'
        let blogerror = typeOfBlog === TECHNICAL ? 'blogerrormessageTech' : 'blogerrormessagePersonal'
        this.setState({
            [blogIntro]: '',
            [blogNumber]: '',
            [date]: '',
            [description]: '',
            [header]: '',
            [image]: '',
            [liked]: '',
            [blogList]: blogArray,
            [blogerror]: null
        });
        notify.show('Blog Added!', "custom", 5000, myColor);
    }



    submitBlog = (typeOfBlog) => {
        if (typeOfBlog === TECHNICAL) {
            if (this.state.blogHeader === '' || this.state.blogNumber === '' || this.state.blogIntro === '') {
                console.log('submitBlogTech', 'Please fill all of the above information', typeOfBlog)
                this.setState({
                    blogerrormessageTech: 'Please fill all of the above information'
                })
            } else {
                console.log('submitBlogTech2', typeOfBlog)
                this.fireSubBlogData(typeOfBlog)
            }

        } else {
            if (this.state.blogHeaderPersonal === '' || this.state.blogNumberPersonal === '' || this.state.blogIntroPersonal === '') {
                console.log('submitBlogPersonal', 'Please fill all of the above information', typeOfBlog)
                this.setState({
                    blogerrormessagePersonal: 'Please fill all of the above information'
                })
            } else {
                console.log('submitBlogPersonal2', typeOfBlog)
                this.fireSubBlogData(typeOfBlog)
            }
        }

    }


    deleteBlog = (typeOfBlog, number, index) => {
        console.log("delete blog", typeOfBlog, number, index);
        var db = firebase.firestore();
        var work_query = db.collection(typeOfBlog === TECHNICAL ? "pradnyanandBlogs" : "pradnyanandPersonalBlogs").where('blogNumber', '==', number);
        work_query.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                doc.ref.delete();
            });
        });
        let blogArray = typeOfBlog === TECHNICAL ? this.state.blogList : this.state.personalBlogList
        blogArray.splice(index, 1);
        let blogname = typeOfBlog === TECHNICAL ? 'blogList' : 'personalBlogList'
        this.setState({
            [blogname]: blogArray
        })
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    signInWithGoogle = () => {
        const auth = firebase.auth();
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider);
        auth.onAuthStateChanged(userAuth => {
            console.log('signInUser', userAuth)
            if (userAuth) {
                this.setState({
                    username: userAuth.displayName,
                    userimage: userAuth.photoURL,
                    useremail: userAuth.email
                })
            }
        });
    }


    render() {
        let blogsUI = null;

        if (!this.state.isAdmin) {
            blogsUI = (
                <div className={styles.containerdiv}>
                    <div className={styles.flexContainerChildlogin}>
                        <div className={styles.textDiv}>
                            <p className={styles.textDivp}>Pradnyanand's Admin Panel.</p>
                        </div>

                        <input
                            type='text'
                            name='adminEmail'
                            placeholder="Email Address"
                            value={this.state.adminEmail}
                            className={styles.input}
                            style={{ fontSize: 16, padding: 5, backgroundColor: 'transparent', marginBottom: 5, color: 'white', borderRadius: 1, borderWidth: 1, borderColor: 'white', fontFamily: 'Roboto Mono', }}
                            onChange={this.myChangeHandler}
                        />
                        <input
                            type='text'
                            name='adminPassword'
                            value={this.state.adminPassword}
                            placeholder="Password"
                            className={styles.input}
                            style={{ fontSize: 16, padding: 5, backgroundColor: 'transparent', marginBottom: 5, color: 'white', borderRadius: 1, borderWidth: 1, borderColor: 'white', marginTop: 20, fontFamily: 'Roboto Mono' }}
                            onChange={this.myChangeHandler}
                        />
                        <div>
                            <button
                                className={styles.button}
                                style={{ marginTop: 10 }}
                                onClick={this.signInAdmin}>
                                Login
                            </button>
                        </div>
                        {this.state.adminError != null ? <p style={{ color: 'red' }}>{this.state.adminError}</p> : null}
                    </div>

                </div>
            )

        } else {

            blogsUI = (
                <div className={styles.containerdiv}>
                    <div className={styles.flexContainerChild}>

                        <div className={styles.flexContainerChild2}>

                            <p style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Roboto Mono', marginLeft: 30, alignSelf: 'center', textAlign: 'center' }} >Pradnyanand's Admin Panel.</p>

                            <p style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Roboto Mono', marginLeft: 40, marginTop: 30, alignSelf: 'center', }}>Add a new work</p>
                            <div style={{ flexDirection: 'column', marginLeft: 30, display: 'flex', marginTop: 10 }}>

                                <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex' }}>
                                    <input
                                        type='text'
                                        name='workNumber'
                                        placeholder="Your workNumber"
                                        value={this.state.workNumber}
                                        className={styles.input}
                                        style={inputFiled}
                                        onChange={this.myChangeHandler}
                                    />
                                    <input
                                        type='text'
                                        name='workTitle'
                                        value={this.state.workTitle}
                                        placeholder="Your workTitle"
                                        className={styles.input}
                                        style={inputFiled}
                                        onChange={this.myChangeHandler}
                                    />
                                </div>
                                <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex' }}>
                                    <input
                                        type='text'
                                        name='workInfo'
                                        value={this.state.workInfo}
                                        placeholder="Your workInfo"
                                        className={styles.input}
                                        style={inputFiled}
                                        onChange={this.myChangeHandler}
                                    />
                                    <input
                                        type='text'
                                        name='workUrlTitle'
                                        value={this.state.workUrlTitle}
                                        placeholder="Your workUrlTitle"
                                        className={styles.input}
                                        style={inputFiled}
                                        onChange={this.myChangeHandler}
                                    />
                                </div>
                                <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex' }}>
                                    <input
                                        type='text'
                                        name='workUrl'
                                        value={this.state.workUrl}
                                        placeholder="Your workUrl"
                                        className={styles.input}
                                        style={inputFiled}
                                        onChange={this.myChangeHandler}
                                    />
                                    <input
                                        type='text'
                                        name='workImage'
                                        value={this.state.workImage}
                                        placeholder="Your workImage"
                                        className={styles.input}
                                        style={inputFiled}
                                        onChange={this.myChangeHandler}
                                    />
                                </div>
                                <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex' }}>

                                    <input
                                        type='text'
                                        name='workStack1'
                                        value={this.state.workStack1}
                                        placeholder="Your workStack1"
                                        className={styles.input}
                                        style={inputFiled}
                                        onChange={this.myChangeHandler}
                                    />


                                    <input
                                        type='text'
                                        name='workStack2'
                                        value={this.state.workStack2}
                                        placeholder="Your workStack2"
                                        className={styles.input}
                                        style={inputFiled}
                                        onChange={this.myChangeHandler}
                                    />

                                </div>
                                <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex' }}>
                                    <input
                                        type='text'
                                        name='workStack3'
                                        value={this.state.workStack3}
                                        placeholder="Your workStack3"
                                        className={styles.input}
                                        style={inputFiled}
                                        onChange={this.myChangeHandler}
                                    />
                                </div>

                            </div>
                            <div style={{ display: 'inline-block', }} > <button className={styles.button} style={{ marginTop: 10, marginLeft: 40 }} onClick={this.submitWork}>Submit Work</button> </div>
                            {this.state.workerrormessage != null ? <p style={{ color: 'red' }}>{this.state.workerrormessage}</p> : null}

                            <div className={styles.flexContainerCommentMain}>
                                {this.state.workList != null ? this.state.workList.map((item, index) => {
                                    return (
                                        <div className={styles.flexContainerComment} >
                                            <div className={styles.flexContainerComment2} >
                                                <p style={stylesSheet.headerText}>Work Number <span><p style={stylesSheet.headerTextValue}>{item.workNumber}</p></span></p>
                                                <p style={stylesSheet.headerText}>Work Title<span><p style={stylesSheet.headerTextValue}>{item.workTitle}</p></span></p>
                                                <p style={stylesSheet.headerText}>Work Info<span><p style={stylesSheet.headerTextValue}>{item.workInfo}</p></span></p>
                                                <div>
                                                    <p style={stylesSheet.headerText}>Work Url</p>
                                                    <a target="_blank" style={{ fontSize: 14, marginTop: 5, fontWeight: 'normal', color: 'white', fontFamily: 'Roboto Mono', marginLeft: 15 }} href={item.workUrl}>{item.workUrlTitle}</a>
                                                </div>
                                                <div style={{ marginLeft: 15 }}>
                                                    <p style={{ fontSize: 14, fontWeight: 'bold', color: 'yellow', fontFamily: 'Roboto Mono', }}>Work Stack</p>
                                                    <div>
                                                        <div style={{ display: 'inline-block', }}><p style={{ fontSize: 14, color: 'white', fontFamily: 'Roboto Mono', }}>{item.stackOne}</p> </div>
                                                        <div style={{ display: 'inline-block', marginLeft: 5 }}><p style={{ fontSize: 14, color: 'white', fontFamily: 'Roboto Mono', }}>{item.stackTwo}</p></div>
                                                        <div style={{ display: 'inline-block', marginLeft: 5 }}><p style={{ fontSize: 14, color: 'white', fontFamily: 'Roboto Mono', }}>{item.stackThree}</p></div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'inline-block', marginLeft: 15 }} > <button className={styles.button} style={{ marginTop: 10 }} onClick={() => { if (window.confirm('Are you sure you wish to work ' + item.workNumber)) this.deleteWork(item.workNumber, index) }}>Delete Work {item.workNumber}</button> </div>

                                            </div>
                                        </div>
                                    )
                                }) : null}
                            </div>
                        </div>
                    </div>


                    <div className={styles.flexContainerChild}>
                        <div className={styles.flexContainerChild2}>
                            <p style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Roboto Mono', marginLeft: 30, marginTop: 30 }}>Add a new technical blog</p>
                            <div style={{ flexDirection: 'column', marginLeft: 30, display: 'flex', marginTop: 10 }}>
                                <input
                                    type='text'
                                    name='blogNumber'
                                    placeholder="Your blogNumber"
                                    value={this.state.blogNumber}
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogHeader'
                                    value={this.state.blogHeader}
                                    placeholder="Your blogHeader"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogIntro'
                                    value={this.state.blogIntro}
                                    placeholder="Your blogIntro"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogDate'
                                    value={this.state.blogDate}
                                    placeholder="Your blogDate"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogDescription'
                                    value={this.state.blogDescription}
                                    placeholder="Your blogDescription"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogLiked'
                                    value={this.state.blogLiked}
                                    placeholder="Your blogLiked"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogImage'
                                    value={this.state.blogImage}
                                    placeholder="Your blogImage"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                            </div>
                            <div style={{ display: 'inline-block', }} > <button className={styles.button} style={{ marginTop: 10, marginLeft: 30 }} onClick={() => this.submitBlog(TECHNICAL)}>Submit Tech Blog</button> </div>
                            {this.state.blogerrormessageTech != null ? <p style={{ color: 'red' }}>{this.state.blogerrormessageTech}</p> : null}
                            <div className={styles.flexContainerCommentMain}>
                                {this.state.blogList != null ? this.state.blogList.map((item, index) => {
                                    return (
                                        <div className={styles.flexContainerComment} >
                                            <div className={styles.flexContainerComment2} >

                                                <p style={stylesSheet.headerText}>Blog Number <span><p style={stylesSheet.headerTextValue}>{item.blogNumber}</p></span></p>
                                                <p style={stylesSheet.headerText}>Blog Header <span><p style={stylesSheet.headerTextValue}>{item.header}</p></span></p>
                                                <p style={stylesSheet.headerText}>Blog Intro<span><p style={stylesSheet.headerTextValue}>{item.blogIntro.substring(0, 100)}</p></span></p>

                                                <p style={stylesSheet.headerText}>Blog Date<span><p style={stylesSheet.headerTextValue}>{item.date}</p></span></p>
                                                <p style={stylesSheet.headerText}>Blog Description<span><p style={stylesSheet.headerTextValue}>{item.description.substring(0, 200)}</p></span></p>
                                                <p style={stylesSheet.headerText}>Blog Liked<span><p style={stylesSheet.headerTextValue}>{item.liked}</p></span></p>


                                                {item.image && <img src={item.image} className={styles.imageData} />}
                                                <div>
                                                    <button
                                                        className={styles.button}
                                                        style={{ marginTop: 10, marginLeft: 15 }}
                                                        onClick={() => { if (window.confirm('Are you sure you wish to delete technical blog ' + item.blogNumber)) this.deleteBlog(TECHNICAL, item.blogNumber, index) }}>
                                                        Delete Blog {item.workNumber}
                                                    </button>
                                                </div>

                                            </div>
                                        </div>

                                    )
                                }) : null}
                            </div>
                        </div>
                    </div>


                    <div className={styles.flexContainerChild}>
                        <div className={styles.flexContainerChild2}>
                            <p style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Roboto Mono', marginLeft: 30, marginTop: 30 }}>Add a new personal blog</p>
                            <div style={{ flexDirection: 'column', marginLeft: 30, display: 'flex', marginTop: 10 }}>
                                <input
                                    type='text'
                                    name='blogNumberPersonal'
                                    placeholder="Your blogNumberPersonal"
                                    value={this.state.blogNumberPersonal}
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogHeaderPersonal'
                                    value={this.state.blogHeaderPersonal}
                                    placeholder="Your blogHeaderPersonal"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogIntroPersonal'
                                    value={this.state.blogIntroPersonal}
                                    placeholder="Your blogIntroPersonal"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogDatePersonal'
                                    value={this.state.blogDatePersonal}
                                    placeholder="Your blogDatePersonal"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogDescriptionPersonal'
                                    value={this.state.blogDescriptionPersonal}
                                    placeholder="Your blogDescriptionPersonal"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogLikedPersonal'
                                    value={this.state.blogLikedPersonal}
                                    placeholder="Your blogLikedPersonal"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='blogImagePersonal'
                                    value={this.state.blogImagePersonal}
                                    placeholder="Your blogImagePersonal"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                            </div>
                            <div style={{ display: 'inline-block', }} > <button className={styles.button} style={{ marginTop: 10, marginLeft: 30 }} onClick={() => this.submitBlog(PERSONAL)}>Submit Personal Blog</button> </div>
                            {this.state.blogerrormessagePersonal != null ? <p style={{ color: 'red' }}>{this.state.blogerrormessagePersonal}</p> : null}
                            <div className={styles.flexContainerCommentMain} >
                                {this.state.personalBlogList != null ? this.state.personalBlogList.map((item, index) => {
                                    return (
                                        <div className={styles.flexContainerComment} >
                                            <div className={styles.flexContainerComment2} >

                                                <p style={stylesSheet.headerText}>Blog Number <span><p style={stylesSheet.headerTextValue}>{item.blogNumber}</p></span></p>
                                                <p style={stylesSheet.headerText}>Blog Header <span><p style={stylesSheet.headerTextValue}>{item.header}</p></span></p>
                                                <p style={stylesSheet.headerText}>Blog Intro <span><p style={stylesSheet.headerTextValue}>{item.blogIntro.substring(0, 100)}</p></span></p>
                                                <p style={stylesSheet.headerText}>Blog Date <span><p style={stylesSheet.headerTextValue}>{item.date}</p></span></p>
                                                <p style={stylesSheet.headerText}>Blog Description<span><p style={stylesSheet.headerTextValue}>{item.description.substring(0, 200)}</p></span></p>
                                                <p style={stylesSheet.headerText}>Blog Liked <span><p style={stylesSheet.headerTextValue}>{item.liked}</p></span></p>
                                                {item.image && <img src={item.image} className={styles.imageData} />}
                                                <div>
                                                    <button className={styles.button} style={{ marginTop: 10, marginLeft: 15 }} onClick={() => { if (window.confirm('Are you sure you wish to delete personal blog  ' + item.blogNumber)) this.deleteBlog(PERSONAL, item.blogNumber, index) }}>Delete Blog {item.workNumber}</button>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                }) : null}
                            </div>
                        </div>
                    </div>

                    <div className={styles.flexContainerChild}>
                        <div className={styles.flexContainerChild2}>
                            <p style={{ fontWeight: 'bold', color: 'white', fontFamily: 'Roboto Mono', marginLeft: 30, marginTop: 30 }}>Add Your status</p>
                            <div style={{ flexDirection: 'column', marginLeft: 30, display: 'flex', marginTop: 10 }}>
                                <input
                                    type='text'
                                    name='appUploadName'
                                    placeholder="Your appUploadName"
                                    value={this.state.appUploadName}
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='appUploadUrl'
                                    value={this.state.appUploadUrl}
                                    placeholder="Your appUploadUrl"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='newThing'
                                    value={this.state.newThing}
                                    placeholder="Your newThing"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='recentBlogUrl'
                                    value={this.state.recentBlogUrl}
                                    placeholder="Your recentBlogUrl"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='workingWith'
                                    value={this.state.workingWith}
                                    placeholder="Your workingWith"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='workingWithUrl'
                                    value={this.state.workingWithUrl}
                                    placeholder="Your workingWithUrl"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='youTubeTitle'
                                    value={this.state.youTubeTitle}
                                    placeholder="Your youTubeTitle"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                                <input
                                    type='text'
                                    name='youTubeTitleUrl'
                                    value={this.state.youTubeTitleUrl}
                                    placeholder="Your youTubeTitleUrl"
                                    className={styles.input}
                                    style={inputFiled}
                                    onChange={this.myChangeHandler}
                                />
                            </div>
                            <div style={{ display: 'inline-block', }} > <button className={styles.button} style={{ marginTop: 10, marginLeft: 30 }} onClick={() => this.updateStatus()}>Submit Status</button> </div>

                            <div className={styles.flexContainerCommentMain}>

                                <div className={styles.flexContainerComment} >
                                    <div className={styles.flexContainerComment2} >
                                        <p style={stylesSheet.headerText}>App Upload Name<span><p style={stylesSheet.headerTextValue}>{this.state.appUploadName}</p></span></p>
                                        <p style={stylesSheet.headerText}>App Upload Url<span><p style={stylesSheet.headerTextValue}>{this.state.appUploadUrl}</p></span></p>
                                        <p style={stylesSheet.headerText}>YouTube Title<span><p style={stylesSheet.headerTextValue}>{this.state.youTubeTitle}</p></span></p>

                                        <p style={stylesSheet.headerText}>YouTube Title Url<span><p style={stylesSheet.headerTextValue}>{this.state.youTubeTitleUrl}</p></span></p>
                                        <p style={stylesSheet.headerText}>Working With<span><p style={stylesSheet.headerTextValue}>{this.state.workingWith}</p></span></p>
                                        <p style={stylesSheet.headerText}>Working With Url<span><p style={stylesSheet.headerTextValue}>{this.state.workingWithUrl}</p></span></p>
                                        <p style={stylesSheet.headerText}>Recent Blog Url<span><p style={stylesSheet.headerTextValue}>{this.state.recentBlogUrl}</p></span></p>
                                        <p style={stylesSheet.headerText}>New Thing<span><p style={stylesSheet.headerTextValue}>{this.state.newThing}</p></span></p>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            )
        }

        return (
            <div style={{ backgroundColor: '#000000', }}>
                {blogsUI}
                <Notifications />
            </div>
        )
    }

}

export default Admin